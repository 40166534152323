'use client'
import Header from '@/src/components/Header/Header'
import styles from '@/src/components/Layout/TopBar/TopBar.module.scss'
import SGABar from '@/src/components/SGABar'
import ShowAddressPending from '@/src/components/ShowAddressPending'
import useAuthGuard from '@/src/hooks/useAuthGuard'
import useScrollDir from '@/src/hooks/useScrollDir'
import useWindowScroll from '@/src/hooks/useWindowScroll'
import cn from 'classnames'
import React, { useState } from 'react'

type Props = {
  inGame?: boolean
  isLoggedIn?: boolean
  disableSGABar?: boolean
  disableHeaderActions?: boolean
  secondaryHeaderAction?: React.ReactElement
  moving?: boolean
  blurred?: boolean
  searchReplaceUrl?: boolean
}

const bgBase = 'absolute w-full transition-all duration-300'

const TopBar = ({
  inGame,
  disableSGABar,
  disableHeaderActions,
  secondaryHeaderAction,
  moving = true,
  blurred = false,
  searchReplaceUrl = false,
}: Props) => {
  const { isLoggedIn } = useAuthGuard()
  const scrollDirection = useScrollDir()
  const windowScroll = useWindowScroll()
  const [addressPending, setAddressPending] = useState(false)

  const isScrollTop = windowScroll.y < 35
  const shouldExpand = moving && scrollDirection === -1 && !isScrollTop
  const showHeader = !moving || scrollDirection === -1 || isScrollTop

  return (
    <div
      className={cn('fixed left-0 top-0 z-20 w-full', {
        '!absolute': !moving,
      })}
    >
      {!blurred && (
        <>
          <div
            className={cn(
              styles.bg,
              'absolute z-10 w-full',
              addressPending ? 'h-16' : 'h-[2.4rem]',
            )}
          />

          <div
            className={cn(
              bgBase,
              shouldExpand ? styles.bgExpandedSolid : styles.bg,
              addressPending ? 'h-16' : 'h-[2.4rem]',
            )}
          />

          <div
            className={cn(
              bgBase,
              shouldExpand ? styles.bgExpandedOpacity : 'h-[2.4rem]',
            )}
          />
        </>
      )}
      <ShowAddressPending
        addressPending={addressPending}
        setAddressPending={setAddressPending}
      />
      <div id='sgabar' className='flex w-full justify-center'>
        {!disableSGABar && (
          <SGABar
            searchReplaceUrl={searchReplaceUrl}
            isInGame={blurred || inGame}
            isLoggedIn={isLoggedIn}
          />
        )}
        {!blurred && (
          <Header
            searchReplaceUrl={searchReplaceUrl}
            disableActions={disableHeaderActions}
            secondaryAction={secondaryHeaderAction}
            showHeader={showHeader}
            addressPending={addressPending}
          />
        )}
      </div>
    </div>
  )
}

export default TopBar
