import Search from '@/src/components/Header/Search'
import Overlay from '@/src/components/Overlay'
import { useGetPositioningGroupQuery } from '@/src/services/games'
import { useSearchQuery } from '@/src/services/nextApi'
import { debounce } from '@/src/utils/debounce.tsx'
import { ChangeEvent, useMemo, useState } from 'react'
import { MdOutlineClose } from 'react-icons/md'

type SearchWidgetProps = {
  visible: boolean
  focusSearch: boolean
  onClose: () => void
  replaceUrl?: boolean
}

const SearchWidget = ({
  visible,
  focusSearch,
  onClose,
  replaceUrl,
}: SearchWidgetProps) => {
  const [searchQuery, setSearchQuery] = useState('')
  const { data: mostPopular } = useGetPositioningGroupQuery({
    group: 'popular',
    size: 4,
  })

  const { data: searchResult, isLoading } = useSearchQuery(searchQuery, {
    skip: !visible || !searchQuery,
  })

  const searchGames = useMemo(
    () =>
      debounce((e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value)
      }, 350),
    [setSearchQuery],
  )

  const filteredSearchResult = searchResult?.result.filter((item) => item.item.games.length > 1)

  const isEmpty = searchQuery === ''

  return (
    <div className='relative'>
      {visible && <Overlay onClick={() => onClose()} search />}
      <Search visible={visible}>
        <button
          aria-label='close'
          className='right-3 top-3 flex w-fit content-center items-end self-end rounded-full bg-primary-light p-1.5'
          onClick={() => onClose()}
          style={{ alignSelf: 'flex-end' }}
        >
          <MdOutlineClose className='opacity-50' />
        </button>

        <Search.Input
          focusSearch={focusSearch}
          className='mt-4 translate-x-28'
          onSearch={searchGames}
          defaultValue={searchQuery}
        />
        <Search.Result
          items={!isEmpty ? filteredSearchResult || [] : []}
          popularItems={mostPopular?.families || []}
          isLoading={isLoading && searchQuery !== ''}
          isEmpty={isEmpty}
          closeSearch={onClose}
          replaceUrl={replaceUrl}
        />
      </Search>
    </div>
  )
}

export default SearchWidget
