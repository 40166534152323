const GameLimitsIcon = ({ width = '51px', height = '45px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 51 45'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>sga-game-limits</title>
      <g
        id='sga-game-limits'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='Group'
          transform='translate(0.200000, 0.700000)'
          fillRule='nonzero'
        >
          <path
            d='M21.3,41.6 C10.1,41.6 1,32.5 1,21.3 C1,10.1 10.1,1 21.3,1 C32.5,1 41.6,10.1 41.6,21.3 C41.6,32.5 32.5,41.6 21.3,41.6 Z'
            id='Path'
            fill='#FFFFFF'
          />
          <path
            d='M21.3,2 C32,2 40.6,10.7 40.6,21.3 C40.6,32 31.9,40.6 21.3,40.6 C10.6,40.6 2,31.9 2,21.3 C2,10.6 10.6,2 21.3,2 M21.3,8.8817842e-16 C9.6,8.8817842e-16 -3.55271368e-15,9.5 -3.55271368e-15,21.3 C-3.55271368e-15,33 9.5,42.6 21.3,42.6 C33,42.6 42.6,33.1 42.6,21.3 C42.5,9.5 33,8.8817842e-16 21.3,8.8817842e-16 L21.3,8.8817842e-16 Z'
            id='Shape'
            fill='#009739'
          />
        </g>
        <line
          x1='15.7'
          y1='9.3'
          x2='23.4'
          y2='21.5'
          id='Path'
          stroke='#009739'
          strokeWidth='3.871'
          fill='#FFFFFF'
          fillRule='nonzero'
          strokeLinecap='round'
        />
        <line
          x1='14.3'
          y1='28.3'
          x2='23.5'
          y2='21.9'
          id='Path'
          stroke='#009739'
          strokeWidth='3.871'
          fill='#FFFFFF'
          fillRule='nonzero'
          strokeLinecap='round'
        />
        <circle
          id='Oval'
          fill='#009739'
          fillRule='nonzero'
          cx='37.6'
          cy='31.2'
          r='12.9'
        />
        <g
          id='Group'
          transform='translate(30.300000, 24.800000)'
          fill='#FFFFFF'
          fillRule='nonzero'
        >
          <polygon
            id='Path'
            points='0 3.55271368e-15 2.7 3.55271368e-15 2.7 5.4 2.7 5.4 6.9 7.10542736e-15 10.3 7.10542736e-15 5.5 6.1 10.5 12.8 7.2 12.8 2.7 6.8 2.7 6.8 2.7 12.8 7.10542736e-15 12.8 7.10542736e-15 3.55271368e-15'
          />
          <path
            d='M13.8,4.4 L13.8,5.6 L13.8,5.6 C14,5.2 14.3,4.9 14.7,4.6 C15,4.4 15.3,4.2 15.6,4.2 C15.8,4.2 15.9,4.2 16.1,4.2 L16.1,6.9 C15.9,6.9 15.7,6.8 15.5,6.8 C15,6.8 14.6,7 14.3,7.3 C14,7.7 13.9,8.2 13.9,8.8 L13.9,12.5 L11.4,12.5 L11.4,4.2 L13.8,4.2 L13.8,4.4 Z'
            id='Path'
          />
        </g>
        <line
          x1='5'
          y1='22'
          x2='7.4'
          y2='22'
          id='Path'
          stroke='#009739'
          strokeWidth='1.9355'
          strokeLinecap='round'
        />
        <line
          x1='21.7'
          y1='7.7'
          x2='21.7'
          y2='5.3'
          id='Path'
          stroke='#009739'
          strokeWidth='1.9355'
          strokeLinecap='round'
        />
        <line
          x1='21.5'
          y1='39.2'
          x2='21.5'
          y2='36.8'
          id='Path'
          stroke='#009739'
          strokeWidth='1.9355'
          strokeLinecap='round'
        />
      </g>
    </svg>
  )
}

export default GameLimitsIcon
