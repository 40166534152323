import { PositioningProvidersType } from '@/src/services/types'
import { FreeSpinsType } from '@/src/services/types/payment'
import { getProviderName } from '@/src/utils/helpers/gameHelper'

export const freeSpinsHelper = (
  availableFreeSpins: FreeSpinsType,
  providers: PositioningProvidersType,
) => {
  const check =
    availableFreeSpins &&
    availableFreeSpins?.bonuses?.transactions.length > 0 &&
    providers
  return check
    ? availableFreeSpins?.bonuses?.transactions
        ?.filter((t) => t.status === 'active')
        ?.flatMap((transaction) => {
          return transaction?.games?.map((game) => {
            return {
              providerName: `${getProviderName(game.provider, providers)}`,
              expirationTime: transaction.expirationTime,
              ...game,
            }
          })
        })
    : []
}

export default freeSpinsHelper
