import styles from '@/src/components/ShowAddressPending/ShowAddressPending.module.scss'
import { useGetPlayerQuery } from '@/src/services/player.tsx'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

type ShowAddressPendingProps = {
  addressPending: boolean
  setAddressPending: Dispatch<SetStateAction<boolean>>
}
const ShowAddressPending = ({
  addressPending,
  setAddressPending,
}: ShowAddressPendingProps) => {
  const { t } = useTranslation(['common'])
  const { data: user } = useGetPlayerQuery()

  useEffect(() => {
    if (user?.addressVerified) {
      setAddressPending(false)
    } else if (
      user?.nameVerified &&
      user?.ageVerified &&
      !user?.addressVerified
    ) {
      setAddressPending(true)
    }
  }, [
    setAddressPending,
    user?.addressVerified,
    user?.ageVerified,
    user?.nameVerified,
  ])

  return (
    <>
      {addressPending && (
        <div className={styles['show-address-pending']}>
          {t('address-verification-pending')}
        </div>
      )}
    </>
  )
}

export default ShowAddressPending
