import LocalizedLink from '@/src/components/LocalizedLink'
import GameThumbnail from '@/src/components/Thumbnail/GameThumbnail'
import { GameType } from '@/src/services/types'
import { FamilyType } from '@/src/services/types/games'
import { JackpotType } from '@/src/services/types/jackpot'
import { isMobile } from '@/src/utils/helpers/browserHelpers'
import { slugify } from '@/src/utils/slugHelper'
import type { JSX } from 'react'

// @TODO refactor me into a component
const buildGameThumbnails = (
  gameFamilies: FamilyType[],
  showName: boolean,
  favouriteGames: GameType[],
  jackpots: JackpotType[] | undefined,
  group: string,
  forcePreload: boolean,
  preloadAmount = 4,
) => {
  return gameFamilies
    .filter((family) => family.status === 'ENABLED')
    .map((family, index) => {
      const game = gameByDevice(family.games)
      const thumbnailGameName = normalizedGameNameThumbnail(family.games)
      if (!game?.gameId || !game.name) return
      return (
        <LocalizedLink
          draggable={'false'}
          data-familyid={family.familyId}
          data-group={group}
          key={game.gameId}
          href={`/game/${slugify(family.providerName)}/${game.friendlyName}`}
        >
          <GameThumbnail
            jackpots={jackpots}
            showName={showName}
            favourite={isFavouriteGame(favouriteGames, game)}
            slug={game.friendlyName}
            game={game}
            thumbnailGameName={thumbnailGameName}
            priority={forcePreload && index <= preloadAmount}
            displayName={family.familyName}
          />
        </LocalizedLink>
      )
    })
    .filter((r): r is JSX.Element => typeof r !== 'undefined')
}

const gameByDevice = (games: GameType[], forceSsr?: boolean) => {
  if (typeof window === 'object' && !forceSsr) {
    return games.filter((game) => game.mobile === isMobile())[0]
  }
  // Default prerender DESKTOP
  return games.filter((game) => game.mobile === false)[0]
}

// We always render the DESKTOP version thumbnail
const normalizedGameNameThumbnail = (games: GameType[]) => {
  return games.filter((game) => game.mobile === false)[0]?.name
}

const isFavouriteGame = (favouriteGames: GameType[], game: GameType) => {
  return !!favouriteGames?.find((g) => g.gameId === game.gameId)
}

export { buildGameThumbnails, gameByDevice, normalizedGameNameThumbnail }
