import SearchInput from '@/src/components/Header/Search/Search.Input'
import SearchResult from '@/src/components/Header/Search/Search.Result'
import React from 'react'

type SearchProps = {
  visible: boolean
  children: React.ReactNode
}

const Search = ({ visible, children }: SearchProps) => {
  return visible ? (
    <div className='fixed bottom-0 left-0 right-0 top-0 z-50 flex h-full w-full animate-in flex-col items-center justify-center zoom-in-50'>
      <div className='h-full w-full max-w-md'>
        <div className='flex max-h-full flex-col overflow-auto px-4 pt-12'>
          {children}
        </div>
      </div>
    </div>
  ) : null
}
Search.Input = SearchInput
Search.Result = SearchResult

export default Search
