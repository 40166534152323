'use client'

import styles from '@/src/components/SnackBar/SnackBar.module.scss'
import cn from 'classnames'
import React, { memo } from 'react'
import { FaCheckSquare } from 'react-icons/fa'

type SnackBarProps = {
  type?: 'success' | 'warning' | 'error'
  children?: React.ReactNode
  showSnackbar: boolean
}

function SnackBar({ type = 'success', children, showSnackbar }: SnackBarProps) {
  const snackBarBaseClasses =
    'bg-green-700 flex flex-row rounded p-4 relative text-center justify-center items-center rounded-md'
  const snackBarClasses = `${snackBarBaseClasses} ${showSnackbar ? 'top-4' : '-top-32'}`
  return (
    <div className='fixed left-0 right-0 top-16 z-10 flex h-0 items-center justify-center'>
      <div
        className={cn(snackBarClasses)}
        tabIndex={0} // Allow focusing for better accessibility
      >
        {type === 'success' && (
          <FaCheckSquare
            className='text-green-300'
            style={{ width: 40, height: 40 }}
          />
        )}
        <div className='ml-4 leading-8 text-white'>
          <span
            className={cn(
              styles.outerSpan,
              showSnackbar && styles.outerSpanExpanded,
            )}
          >
            <span
              className={cn(
                styles.innerSpan,
                'flex flex-row items-center justify-center text-left text-white',
              )}
            >
              {children && children}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default memo(SnackBar)
