import { api } from '@/src/services/index'
import { AnnouncementsType } from '@/src/services/types/announcements.tsx'
import { FreeSpinsType } from '@/src/services/types/payment.tsx'
import {
  PlayerBalanceType,
  PlayerType,
  PlayerVouchersType,
  SessionResponseType,
} from '@/src/services/types/player.tsx'
import { announcementsListeners } from '@/src/services/utils/announcementsListeners.ts'
import { balanceListener } from '@/src/services/utils/balanceListener.ts'
import { bonusCheckListener } from '@/src/services/utils/bonusCheckListener.ts'
import { vouchersListeners } from '@/src/services/utils/vouchersListeners.tsx'
import {
  clientConnected,
  getWebSocketClient,
} from '@/src/services/utils/webSocketClient.ts'
import { addBreadcrumb } from '@sentry/nextjs'

const websocketApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncements: builder.query<
      AnnouncementsType[],
      SessionResponseType | undefined
    >({
      query: () => `/announcements`,
      transformResponse: (response: { announcements: AnnouncementsType[] }) => {
        return response.announcements
      },
      async onCacheEntryAdded(
        session,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded

          const clientKey = validSession(session)
            ? 'authenticated'
            : 'unauthenticated'

          const client = getWebSocketClient(clientKey)
          const connected = await clientConnected(client)
          if (!connected) return
          const unsubscribe = announcementsListeners(client, updateCachedData)
          await cacheEntryRemoved
          unsubscribe()
        } catch (e) {
          addBreadcrumb({
            category: 'announcementWS',
            message: 'announcement error happened',
            data: { e },
            level: 'error',
          })
        }
      },
    }),

    getFreeSpinsBonusWs: builder.query<FreeSpinsType, void>({
      query: () => '/player/bonuses/freeSpins/v2',
      providesTags: ['Bonuses'],
      async onCacheEntryAdded(_arg, { cacheEntryRemoved, cacheDataLoaded }) {
        try {
          // Make sure cache is ready first
          await cacheDataLoaded
          // Get client singleton
          const client = getWebSocketClient('authenticated')
          // Wait for client to connect
          const connected = await clientConnected(client)
          if (!connected) return
          // Setup event listeners
          const unsubscribe = bonusCheckListener(client)
          // Unsubscribe from listeners when cache is removed
          await cacheEntryRemoved
          unsubscribe()
        } catch (e) {
          addBreadcrumb({
            category: 'freeSpinWS',
            message: 'freeSpin error happened',
            data: { e },
            level: 'error',
          })
        }
      },
    }),

    getVouchers: builder.query<PlayerVouchersType, { data?: PlayerType }>({
      query: () => 'player/vouchers',
      providesTags: ['Vouchers'],
      async onCacheEntryAdded(
        _arg: { data?: PlayerType | null | undefined | never } | null,
        { cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded
          const client = getWebSocketClient('authenticated')
          const connected = await clientConnected(client)
          if (!connected) return
          const unsubscribe = vouchersListeners(client)
          await cacheEntryRemoved
          unsubscribe()
        } catch (e) {
          addBreadcrumb({
            category: 'vouchersWS',
            message: 'voucher error happened',
            data: { e },
            level: 'error',
          })
        }
      },
    }),

    getBalanceWS: builder.query<
      PlayerBalanceType,
      SessionResponseType | undefined
    >({
      query: () => '/player/balance',
      providesTags: ['Balance'],
      onCacheEntryAdded: async (
        session,
        { cacheEntryRemoved, cacheDataLoaded, updateCachedData },
      ) => {
        try {
          await cacheDataLoaded
          if (!validSession(session)) return
          const client = getWebSocketClient('authenticated')
          const connected = await clientConnected(client)
          if (!connected) return
          const unsubscribe = balanceListener(client, updateCachedData)
          await cacheEntryRemoved
          unsubscribe()
        } catch (e) {
          addBreadcrumb({
            category: 'balanceWS',
            message: 'balance error happened',
            data: { e },
            level: 'error',
          })
        }
      },
    }),
  }),

  overrideExisting: process.env.NODE_ENV === 'development',
})

function validSession(session: SessionResponseType | undefined): boolean {
  if (typeof session === 'object' && (session.error || session.errorCode)) {
    return false
  }
  if (typeof session === 'string') {
    return true
  }
  return false
}

export const {
  useGetAnnouncementsQuery,
  useGetVouchersQuery,
  useGetBalanceWSQuery,
  useGetFreeSpinsBonusWsQuery,
} = websocketApi
