import { useGetAllNotificationsQuery } from '@/src/services/fastTrackFusionUrl'
import { AppState } from '@/src/store/store'
import { useSelector } from 'react-redux'

const useMessages = () => {
  const { ftAuthToken } = useSelector((state: AppState) => state.fastTrackAuth)

  const { data: notificationsArray } = useGetAllNotificationsQuery(
    ftAuthToken,
    {
      skip: !ftAuthToken,
    },
  )

  const messagesLength = !!notificationsArray?.Data
    ? notificationsArray?.Data?.filter((message) => message.Event === 'inbox')
        .length
    : 0
  const unreadMessages = !!notificationsArray?.Data
    ? notificationsArray?.Data?.filter((item) => !item.IsRead).length
    : 0

  return {
    messagesData: notificationsArray,
    messagesLength,
    unreadMessages,
  }
}

export default useMessages
