import Modal from '@/src/components/Modal/Modal'
import WithdrawalWarning from '@/src/components/WithdrawalWarning'
import useCurrencyFormatter from '@/src/hooks/useCurrencyFormatter'
import { PlayerBalanceType } from '@/src/services/types'
import { useTranslation } from 'react-i18next'

type WidtdrawalWarningModalProps = {
  onClose: () => void
  onAccept: () => void
  balance?: PlayerBalanceType | undefined
}
const WidtdrawalWarningModal = ({
  onAccept,
  onClose,
  balance,
}: WidtdrawalWarningModalProps) => {
  const { t } = useTranslation(['common', 'compliance'])
  const { formatter } = useCurrencyFormatter()

  return (
    <Modal
      onClose={() => onClose()}
      targetModal={'withdrawal-warning'}
      allowClickOutside={false}
      allowClose={true}
      showCloseButton={false}
      heading={t('common:warning')}
      textSection={t('common:warning-withdrawal', {
        bonus: formatter.format(balance?.promo || 0),
      })}
    >
      <WithdrawalWarning
        onAccept={onAccept}
        onDecline={onClose}
        balance={balance}
      />
    </Modal>
  )
}

export default WidtdrawalWarningModal
