import CounterNotification from '@/src/components/NotificationIcon/CounterNotification'
import useMessages from '@/src/hooks/useMessages'

type NotificationIcon = {
  className?: string
  type?: 'unSelected' | 'border' | 'promo'
  onClick?: (_event: React.MouseEvent<HTMLElement>) => void
}

const MessageNotification = ({
  className,
  type,
  onClick,
}: NotificationIcon) => {
  const { messagesLength } = useMessages()
  const messagesLengthConst = messagesLength || 0
  return (
    <CounterNotification
      value={messagesLengthConst}
      className={className}
      type={type}
      onClick={onClick}
    />
  )
}

export default MessageNotification
