'use client'
import { GameThumbnailLabels } from '@/src/components/Thumbnail'
import classNames from 'classnames'

import ImageLang from '@/src/components/ImageLang'
import HeartIcon from '@/src/components/SvgIcons/HeartIcon'
import classes from '@/src/components/Thumbnail/game.module.scss'
import {
  useAddFavouriteGameMutation,
  useRemoveFavouriteGameMutation,
} from '@/src/services/games'
import { gtmClick } from '@/src/services/gtm_factory.ts'
import { ThumbnailEssentials } from '@/src/services/types/games'
import { GTMClickEventData } from '@/src/services/types/gtm'
import { JackpotType } from '@/src/services/types/jackpot'
import { generateGameImagePath } from '@/src/utils/image'
import { MouseEventHandler, useCallback } from 'react'

export type ThumbnailGameProps = {
  slug?: string
  displayName?: string
  tileSize?: 'small' | 'large'
  game: ThumbnailEssentials
  priority?: boolean
  width?: number
  height?: number
  jackpots?: JackpotType[] | undefined
  favourite?: boolean
  showName?: boolean
  hideLabels?: boolean
  gtm?: GTMClickEventData
  thumbnailGameName?: string
  sliderOverrideCss?: boolean
  onClick?: () => void
}

const GameThumbnail = ({
  displayName,
  game: { name, provider, newGame, gameId },
  jackpots,
  favourite = false,
  showName = false,
  priority,
  width = 250,
  height = 180,
  hideLabels = false,
  gtm,
  thumbnailGameName,
  sliderOverrideCss,
  onClick,
}: ThumbnailGameProps) => {
  const src = generateGameImagePath(provider, thumbnailGameName || name)
  const [addFavouriteGame] = useAddFavouriteGameMutation()
  const [removeFavouriteGame] = useRemoveFavouriteGameMutation()
  const toggleFavorite: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault()
      if (favourite) {
        removeFavouriteGame(gameId)
      } else {
        addFavouriteGame(gameId)
      }
    },
    [addFavouriteGame, removeFavouriteGame, favourite, gameId],
  )

  return (
    <div
      onClick={() => {
        if (gtm) gtmClick(gtm)
        onClick?.()
      }}
      className={`${classes.wrapperSlide}`}
    >
      {!hideLabels && (
        <GameThumbnailLabels
          jackpots={jackpots}
          gameId={gameId}
          newGame={newGame}
          hasNameBar={showName}
        />
      )}
      <ImageLang
        draggable={'false'}
        src={src}
        alt={displayName ?? name}
        priority={!!priority}
        sizes='(max-width: 639px) 50vw, (max-width: 768px) 33vw, (max-width: 1024px) 25vw'
        width={width}
        height={height}
        className={classNames({ [classes.sliderOverride]: sliderOverrideCss })}
      />
      {showName && (
        <div
          className='flex items-center justify-between'
          onClick={toggleFavorite}
        >
          <div className='truncate px-2'>{displayName ?? name}</div>
          <div className='flex min-h-12 min-w-12 items-center justify-center overflow-hidden rounded-br-md bg-gradient-tertiary'>
            <div className='flex min-h-10 min-w-10 items-center justify-center object-contain duration-75 ease-in-out active:scale-[1.075]'>
              <HeartIcon active={favourite} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default GameThumbnail
