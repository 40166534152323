import { formatCurrencyToNumber } from '@/src/utils/format'

export const validNumber = (
  formatter: Intl.NumberFormat,
  value: string,
  partsSymbol?: string,
) => {
  const number = formatCurrencyToNumber(value)

  if (!number) return number

  const _number = formatter.format(Number.isNaN(+number) ? 0 : +number)
  return (
    number.charAt(number.length - 1) === '.' ? _number + '.' : _number
  ).replace(/[.,]/g, partsSymbol || '.')
}
