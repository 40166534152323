import { RoundedButton } from '@/src/components/Button'
import LocalizedLink from '@/src/components/LocalizedLink'
import Styles from '@/src/components/NavBar/Wallet/Wallet.module.scss'
import WidtdrawalWarningModal from '@/src/components/WithdrawalWarning/WageringWarningModal'
import useRouter from '@/src/hooks/Router/useRouter'
import { useGetPreferredWithdrawMethodQuery } from '@/src/services/payment'
import { useGetWithdrawalsTransactionsQuery } from '@/src/services/transactions'
import { PlayerBalanceType } from '@/src/services/types'
import { setReloadPaymentIq } from '@/src/store/slices/configsSlice'
import { closeModal, showModal } from '@/src/store/slices/modalSlice'
import { useAppDispatch } from '@/src/store/store'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { BsArrowClockwise } from 'react-icons/bs'

type WalletButtonsProps = {
  balance?: PlayerBalanceType | undefined
  onClose?: () => void
}

const WalletButtons = ({ balance, onClose }: WalletButtonsProps) => {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { data: transactions } = useGetWithdrawalsTransactionsQuery({
    status: 'pending',
  })

  const { data: preferredWithdrawMethod } =
    useGetPreferredWithdrawMethodQuery(null)

  const minWithdrawal =
    preferredWithdrawMethod?.minAmount?.SEK ||
    preferredWithdrawMethod?.minAmount?.EUR ||
    0
  const availableWithdrawal = balance?.availableWithdrawal || 0
  const canWithdraw = availableWithdrawal >= minWithdrawal

  const pendingWithdrawals = transactions?.count || 0

  const handleAcceptTerms = async () => {
    dispatch(closeModal('withdrawal-warning'))
    router.push('/pnp/withdraw')
  }

  const handleCloseTerms = () => {
    dispatch(closeModal('withdrawal-warning'))
  }

  const showComponent = balance && balance?.promo > 0
  const checkAndShowWaringModal = (e: React.MouseEvent<HTMLElement>) => {
    if (showComponent) {
      dispatch(showModal('withdrawal-warning'))
      e.preventDefault()
    }
  }

  return (
    <>
      <div className='my-9 w-full text-center'>
        <LocalizedLink
          onClick={() => dispatch(setReloadPaymentIq(true))}
          href={
            pendingWithdrawals
              ? '/withdraw/pending?type=deposit'
              : '/pnp/deposit'
          }
          className='mb-5 block w-full'
        >
          <RoundedButton className={`w-60`} size='large' onClick={onClose}>
            {t('deposit')}
          </RoundedButton>
        </LocalizedLink>
        <LocalizedLink
          href={'/pnp/withdraw'}
          className='block w-full'
          onClick={(e) => {
            checkAndShowWaringModal(e)
            dispatch(setReloadPaymentIq(true))
          }}
        >
          <RoundedButton
            type='secondary'
            className={`w-60`}
            size='large'
            disabled={!canWithdraw}
            onClick={onClose}
          >
            {t('withdraw')}
          </RoundedButton>
        </LocalizedLink>
        {!canWithdraw && (
          <div className='mt-2'>
            {t('withdraw.amount.min', {
              min: minWithdrawal,
            })}
          </div>
        )}
        {!!pendingWithdrawals && pendingWithdrawals > 0 && (
          <LocalizedLink
            href='/withdraw/pending'
            key={2}
            className={`${Styles.pendingWithdrawals} relative flex w-full items-center justify-center uppercase`}
            onClick={onClose}
          >
            <>
              <div className='absolute left-1'>
                <BsArrowClockwise
                  className={`${Styles.arrowIcon} ${Styles.pendingIcons}`}
                />
                <AiOutlineClockCircle
                  className={`${Styles.clockIcon} ${Styles.pendingIcons}`}
                />
              </div>
              {t('pending-withdrawals', {
                amount: `${pendingWithdrawals}`,
                defaultValue: 0,
              })}
            </>
          </LocalizedLink>
        )}
      </div>
      <WidtdrawalWarningModal
        onAccept={handleAcceptTerms}
        onClose={handleCloseTerms}
        balance={balance}
      />
    </>
  )
}

export default WalletButtons
