import CounterNotification from '@/src/components/NotificationIcon/CounterNotification'
import { useGetBonuses } from '@/src/hooks/useGetBonuses'

type NotificationIcon = {
  className?: string
  type?: 'unSelected' | 'border' | 'promo'
  onClick?: (_event: React.MouseEvent<HTMLElement>) => void
}

const BonusNotification = ({ className, type, onClick }: NotificationIcon) => {
  const { bonuses } = useGetBonuses()

  return (
    <CounterNotification
      value={bonuses.length}
      className={className}
      type={type}
      onClick={onClick}
    />
  )
}

export default BonusNotification
