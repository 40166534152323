'use client'
import Loading from '@/src/components/Loading'
import LocalizedLink from '@/src/components/LocalizedLink'
import { GameThumbnail } from '@/src/components/Thumbnail'
import { useGetJackpotsQuery } from '@/src/services/jackpots'
import {
  gameByDevice,
  normalizedGameNameThumbnail,
} from '@/src/utils/helpers/thumbnails'
import { slugify } from '@/src/utils/slugHelper'
import { useTranslation } from 'react-i18next'
import { FamilyType, FuseGeneric } from '@/src/services/types/games'

type SearchResultProps = {
  items: FuseGeneric<FamilyType>
  popularItems: FamilyType[] | []
  isLoading: boolean
  isEmpty: boolean
  closeSearch: () => void
  replaceUrl?: boolean
}
const SearchResult = ({
  items,
  popularItems,
  isLoading,
  isEmpty,
  closeSearch,
  replaceUrl,
}: SearchResultProps) => {
  const { data: jackpots } = useGetJackpotsQuery()
  const noItems = !items.length
  const { t } = useTranslation(['common'])

  return (
    <div className='relative mt-5 flex-auto overflow-y-auto'>
      <Loading inline loading={isLoading} transparent={true} />
      {isEmpty && !!popularItems.length && (
        <div className='mb-3 flex items-center text-lg font-semibold uppercase tracking-[0.23px]'>
          {t(`search.popular-games`)}
        </div>
      )}
      {!noItems &&
        items?.map(({ item }, index) => (
          <LocalizedLink
            key={item.familyId + index}
            href={`/game/${slugify(item.providerName)}/${
              item.games[0].friendlyName
            }`}
            className={'flex min-w-0 flex-row py-2'}
            onClick={() => {
              closeSearch()
            }}
            replace={replaceUrl}
          >
            <div className='flex-[1]'>
              <div className='mx-auto'>
                {jackpots && (
                  <GameThumbnail
                    tileSize='small'
                    game={gameByDevice(item.games)}
                    thumbnailGameName={normalizedGameNameThumbnail(item.games)}
                    jackpots={jackpots}
                    gtm={{
                      type: 'game',
                      name: gameByDevice(item.games)?.friendlyName,
                      position: index,
                      category: 'search',
                      targetUrl: `/game/${slugify(item.providerName)}/${
                        item.games[0].friendlyName
                      }`,
                    }}
                  />
                )}
              </div>
            </div>
            <div className='flex flex-[3] items-center truncate px-4 text-left text-xl font-semibold text-white'>
              {item.familyName}
            </div>
          </LocalizedLink>
        ))}
      {isEmpty &&
        popularItems.map((item: FamilyType, index: number) => (
          <LocalizedLink
            key={item.familyId + (item?.position || index)}
            href={`/game/${slugify(item.providerName)}/${
              item.games[0].friendlyName
            }`}
            className={'flex min-w-0 flex-row py-2'}
            onClick={() => {
              closeSearch()
            }}
            replace={replaceUrl}
          >
            <div className='flex-[1]'>
              <div className='mx-auto'>
                {jackpots && (
                  <GameThumbnail
                    tileSize='small'
                    game={gameByDevice(item.games)}
                    thumbnailGameName={normalizedGameNameThumbnail(item.games)}
                    jackpots={jackpots}
                    gtm={{
                      type: 'game',
                      name: gameByDevice(item.games).friendlyName,
                      position: index,
                      category: 'search',
                      targetUrl: `/game/${slugify(item.providerName)}/${
                        item.games[0].friendlyName
                      }`,
                    }}
                  />
                )}
              </div>
            </div>
            <div className='flex flex-[3] items-center truncate px-4 text-left text-xl font-semibold text-white'>
              {item.familyName}
            </div>
          </LocalizedLink>
        ))}

      {!isEmpty && noItems && !isLoading && (
        <div className='col-span-12 flex justify-center p-10 text-center opacity-50'>
          {t('search.no-games')}
        </div>
      )}
    </div>
  )
}

export default SearchResult
