'use client'
import { useTime } from '@/src/contexts/timeContext.tsx'
import { useTranslation } from 'react-i18next'
import { IoIosTimer as TimerIcon } from 'react-icons/io'

type SessionTimerProps = {
  isLoggedIn?: boolean | undefined
}

const SessionTimer = ({ isLoggedIn }: SessionTimerProps) => {
  const { sessionTime } = useTime()
  const { t } = useTranslation('common')

  return isLoggedIn === true && sessionTime ? (
    <div className='flex h-full items-center'>
      <span className='border-s-2 pe-1 ps-2 font-bold uppercase opacity-50'>
        <TimerIcon title={t('session-string')} />
      </span>
      <span className='text-lg font-bold'>{sessionTime}</span>
    </div>
  ) : null
}

export default SessionTimer
