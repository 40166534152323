import { validNumber } from '@/src/components/FormElements/CurrencyInput/helper'
import Input from '@/src/components/FormElements/Input'
import useCurrencyFormatter from '@/src/hooks/useCurrencyFormatter'
import usePartsSymbol from '@/src/hooks/usePartsSymbol.ts'
import { formatCurrencyToNumber } from '@/src/utils/format'
import cn from 'classnames'
import { TranslationsType } from 'i18next'
import React, {
  Dispatch,
  SetStateAction,
  useImperativeHandle,
  useRef,
} from 'react'
import { FieldError } from 'react-hook-form'

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

type InputCustomProps = {
  label?: string
  error?: FieldError
  theme?: 'light' | 'dark'
  inputsize?: 'small' | 'default' | 'large'
  className?: string
  classwrapper?: string
  transdoc?: TranslationsType | TranslationsType[]
  name: string
  type: string
  onChange: (_e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (_e: React.ChangeEvent<HTMLInputElement>) => void
  maxPrecision?: number
  setPrev?: Dispatch<SetStateAction<number>>
  defAmount?: number
  depositInput?: boolean
}

const _validNumber = (
  value: string,
  maxPrecision: number,
  formatter: Intl.NumberFormat,
  partsSymbol: string,
  removePartsSymbol = false,
) => {
  const _validNumber = validNumber(formatter, value, partsSymbol)

  if (!maxPrecision) {
    return _validNumber.replace(partsSymbol, '')
  }

  if (removePartsSymbol) {
    if (_validNumber.slice(-1) === partsSymbol) {
      return _validNumber.replace(partsSymbol, '')
    }
  }

  return _validNumber
}

const CurrencyInput = React.forwardRef<
  HTMLInputElement,
  InputProps & InputCustomProps
>((props: InputCustomProps, outerRef) => {
  const {
    onChange,
    onBlur,
    name,
    defAmount,
    maxPrecision = 0,
    setPrev,
    depositInput,
    ...rest
  } = props

  const innerRef = useRef<HTMLInputElement>(null)
  useImperativeHandle(outerRef, () => innerRef.current!, [])

  const { formatter } = useCurrencyFormatter({
    maxPrecision: maxPrecision,
    minPrecision: 0,
  })

  const { formatter: formatterWithoutSymbol } = useCurrencyFormatter({
    options: {
      style: 'decimal',
    },
    maxPrecision: maxPrecision,
    minPrecision: 0,
  })

  const { partsSymbol } = usePartsSymbol()

  const onBlurValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      e.target.value = _validNumber(
        e.target.value,
        maxPrecision,
        formatter,
        partsSymbol,
        true,
      )
    }
    onBlur(e)
  }

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setPrev) {
      const numberValue = +formatCurrencyToNumber(e.target.value)
      if (!isNaN(numberValue)) {
        setPrev(numberValue)
      } else if (defAmount) {
        setPrev(defAmount)
      }
    }

    e.target.value = _validNumber(
      e.target.value,
      maxPrecision,
      formatterWithoutSymbol,
      partsSymbol,
    )

    onChange(e)
  }

  const onFocusField = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value) {
      e.target.value = _validNumber(
        e.target.value,
        maxPrecision,
        formatterWithoutSymbol,
        partsSymbol,
      )
    }

    onChange(e)
  }

  return (
    <Input
      {...rest}
      ref={innerRef}
      name={name}
      onChange={onChangeValue}
      onFocus={onFocusField}
      onBlur={onBlurValue}
      inputMode='decimal'
      defaultValue={defAmount ? formatter.format(defAmount) : ''}
      type='tel'
      className={cn({
        'rounded-none bg-white/20 text-white shadow-input': depositInput,
      })}
      theme={depositInput ? 'custom' : 'light'}
    />
  )
})
CurrencyInput.displayName = 'CurrencyInput'

export default CurrencyInput
