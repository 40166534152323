import styles from '@/src/components/FormElements/ErrorLabel/ErrorLabel.module.scss'
import cn from 'classnames'
import { TranslationsType } from 'i18next'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import CustomDLimitsErrorLabel from '@/src/components/CustomDLimitsErrorLabel'

type Error =
  | string
  | { key: string; values: Record<string, unknown> }
  | undefined

type ErrorLabelProps = {
  error?: Error
  transdoc?: TranslationsType | TranslationsType[]
  standalone?: boolean
}

const ErrorLabel = ({
  error,
  transdoc = 'common',
  standalone = false,
}: ErrorLabelProps) => {
  const namespaces = Array.isArray(transdoc) ? transdoc : [transdoc]
  const { t } = useTranslation(namespaces)

  const prevErrorRef = useRef<Error>(undefined)
  useEffect(() => {
    prevErrorRef.current = error
  }, [error])

  const renderErrorMessage = (error: Error): string => {
    if (typeof error === 'string') {
      return t(error, { ns: namespaces, defaultValue: error })
    } else if (error && typeof error === 'object') {
      const { key, values } = error
      return t(key, { defaultValue: key, ...values })
    }
    return ''
  }

  const errorMessage = renderErrorMessage(error)

  return (
    <div className='overflow-hidden'>
      <span
        className={cn(styles.root, {
          [styles.standalone]: standalone,
          [styles.startTransiction]: errorMessage,
        })}
        role='alert'
      >
        <div className={styles.text}>
          {error && typeof error !== 'string' && error.key === 'validations.amount.limits' ? (
            <CustomDLimitsErrorLabel />
          ) : errorMessage}
        </div>
      </span>
    </div>
  )
}

export default ErrorLabel
