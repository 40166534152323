import currencyFormatter, {
  FormatterType,
} from '@/src/utils/services/currencyFormatter'
import { useMemo, useState } from 'react'

function useCurrencyFormatter(options: FormatterType = {}) {
  const [formatterOptions] = useState(options)

  const formatter = useMemo(() => {
    return currencyFormatter.formatter({
      ...formatterOptions,
    })
  }, [formatterOptions])

  return { formatter } as const
}

export default useCurrencyFormatter
