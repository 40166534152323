'use client'
import ImageLang from '@/src/components/ImageLang'
import LocalizedLink from '@/src/components/LocalizedLink'
import NavBar from '@/src/components/NavBar'
import WalletButton from '@/src/components/NavBar/Wallet/WalletButton'
import useAuthGuard from '@/src/hooks/useAuthGuard.tsx'
import { usePathname } from '@/src/hooks/usePathname'
import cn from 'classnames'
import { ReactElement, useEffect, useState } from 'react'

type HeaderType = {
  disableActions?: boolean
  secondaryAction?: ReactElement
  searchReplaceUrl?: boolean
  showHeader?: boolean
  addressPending?: boolean
}

const logoDimensions = 'h-[50px] w-[70px] sm:h-[56px] sm:w-[80px]'

const Header = ({
  disableActions,
  secondaryAction,
  searchReplaceUrl,
  showHeader,
  addressPending,
}: HeaderType) => {
  const pathname = usePathname()
  const [showWalletBtn, setShowWalletBtn] = useState(false)
  const { isLoggedIn } = useAuthGuard()

  useEffect(() => {
    if (pathname) {
      if (['/pnp/deposit'].includes(pathname || '')) {
        setShowWalletBtn(!!isLoggedIn)
      } else {
        setShowWalletBtn(
          !['/login', '/forgot-password', '/pnp/swish'].includes(
            pathname || '',
          ),
        )
      }
    }
  }, [isLoggedIn, pathname])

  return (
    <div
      id='header'
      className={cn(
        'absolute mt-9 flex w-full max-w-7xl px-4 pb-8 transition-[top] duration-300 7xl:px-4',
        showHeader ? 'top-0' : 'top-[-110px]',
        addressPending ? 'mt-[3.55rem]' : 'mt-9',
      )}
    >
      <div className='flex basis-1/3 items-center'>
        {!disableActions && <NavBar searchReplaceUrl={searchReplaceUrl} />}
      </div>

      <div className='flex basis-1/3 justify-center'>
        <LocalizedLink href='/' passHref className='relative top-2'>
          <div
            className={`${logoDimensions} absolute inset-0 rounded-full bg-primary-light blur-xl`}
          />
          <ImageLang
            className={`${logoDimensions} relative select-none`}
            priority
            quality={100}
            src='/logos/Lucky-casino-trademark@2x.png'
            alt='lucky-logo'
            width={70}
            height={50}
          />
        </LocalizedLink>
      </div>

      <div className='flex basis-1/3 items-center justify-end'>
        {!disableActions && showWalletBtn && (
          <WalletButton 
            isWalletOpen={false}
            dataAutomationId={
              isLoggedIn ? 'current-balance-id' : 'start-playing-id'
            }
            />
        )}
        {secondaryAction && secondaryAction}
      </div>
    </div>
  )
}

export default Header
