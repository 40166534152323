import SearchWidget from '@/src/widgets/SearchWidget'
import { useState } from 'react'
import { HiOutlineSearch } from 'react-icons/hi'

const SearchBtn = ({ replaceUrl }: { replaceUrl: boolean }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  return (
    <>
      <div
        className='flex h-8 w-8 origin-right scale-90 cursor-pointer items-center justify-center rounded-full p-0'
        onClick={() => setIsSearchOpen(true)}
      >
        <HiOutlineSearch />
      </div>
      <SearchWidget
        onClose={() => setIsSearchOpen(false)}
        focusSearch={isSearchOpen}
        visible={isSearchOpen}
        replaceUrl={replaceUrl}
      />
    </>
  )
}

export default SearchBtn
