import I18N from '@/src/app/_i18n/i18nConfig.ts'
import { getPrismicLang } from '@/src/app/_i18n/utils/i18nHelper.ts'
import { next } from '@/src/services'
import { FamilyType, FuseGeneric } from '@/src/services/types/games.tsx'
import { getFormData } from '@/src/services/utils'

const i18n = I18N.i18n

const nextApi = next.injectEndpoints({
  endpoints: (builder) => ({
    getBuildId: builder.query<{ buildId: string }, void>({
      keepUnusedDataFor: 0,
      query: () => '/build-id',
    }),
    search: builder.query<{ result: FuseGeneric<FamilyType> }, string>({
      keepUnusedDataFor: 60,
      query: (query) => `/search?name=${query}`,
    }),
    prismicBonusesUids: builder.query<
      { prismicBonusesUids: string[] },
      string | undefined
    >({
      keepUnusedDataFor: 60,
      query: (lang = getPrismicLang(i18n.defaultLocale)) =>
        `/prismic-bonuses?lang=${lang}`,
    }),
    // If user is currently loggedin use this endpoint
    gaCookies: builder.mutation<
      null,
      { CID_GLITNOR?: string; GCLID_GLITNOR?: string }
    >({
      query: ({ CID_GLITNOR, GCLID_GLITNOR }) => ({
        url: '/ga-cookies',
        method: 'POST',
        body: getFormData({
          CID_GLITNOR,
          GCLID_GLITNOR,
        }),
      }),
    }),
  }),
  overrideExisting: process.env.NODE_ENV === 'development',
})

export const {
  useGetBuildIdQuery,
  useSearchQuery,
  usePrismicBonusesUidsQuery,
  useGaCookiesMutation,
} = nextApi

//SSR endpoints
export default nextApi
