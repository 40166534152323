import { RoundedButton } from '@/src/components/Button'
import TextLink from '@/src/components/Typography/TextLink'
import { PlayerBalanceType } from '@/src/services/types'
import { useTranslation } from 'react-i18next'

type WithdrawalWarningProps = {
  onAccept: () => void
  onDecline: () => void
  balance?: PlayerBalanceType | undefined
}

const WithdrawalWarning = ({
  //content,
  onAccept,
  onDecline: onCancel,
}: WithdrawalWarningProps) => {
  const { t } = useTranslation(['common', 'compliance'])

  return (
    <div className='flex w-full flex-col items-center justify-start sm:justify-center'>
      <div className='flex w-full flex-col items-center gap-4 text-center'>
        <RoundedButton fullWidth size='large' onClick={onAccept}>
          {t('accept')}
        </RoundedButton>
        <TextLink onClick={onCancel}>{t('cancel')}</TextLink>
      </div>
    </div>
  )
}

export default WithdrawalWarning
