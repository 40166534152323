import { BonusType } from '@/src/services/types/bonus'
import { MethodsType } from '@/src/services/types/payment'
import { PlayerVouchersType, VouchersType } from '@/src/services/types/player'
export const campaignsData = (data: MethodsType | undefined) => {
  return data?.campaigns?.filter((c) => c?.campaignId) || []
}
export const campaignsBonusTypeData = (data: MethodsType | undefined) => {
  return (
    (campaignsData(data).map((v) => ({
      id: v.campaignId,
      slug: v.slug,
      uid: v.campaignId,
      campaignId: v.campaignId,
      name: v.name,
      title: v.title,
      bonusType: 'campaign',
    })) as BonusType[]) || []
  )
}
export const vouchersData = (data: PlayerVouchersType | undefined) => {
  return (
    (data?.vouchers
      ?.filter(
        (v) =>
          !v.claimedTime &&
          v.endTime > new Date().getTime() &&
          v.status?.toUpperCase() === 'ACTIVE',
      )
      ?.map((v: VouchersType) => ({
        id: v.voucherId,
        uid: v.slug,
        voucherId: v.voucherId,
        title: v.directMessage,
        bonusType: 'voucher',
        campaignId: v.campaignId,
        amount: v.amount,
        type: v.type,
        includeBonusAmountToTurnOver: v.includeBonusAmountToTurnOver,
        families: v.families,
        slug: v.slug,
      })) as BonusType[]) || []
  )
}
