import LocalizedLink from '@/src/components/LocalizedLink'
import AvailableFreeSpins from '@/src/components/NavBar/Wallet/AvailableFreeSpins'
import PromoArea from '@/src/components/NavBar/Wallet/PromoArea'
import styles from '@/src/components/NavBar/Wallet/Wallet.module.scss'
import WalletButtons from '@/src/components/NavBar/Wallet/WalletButtons'
import useCurrencyFormatter from '@/src/hooks/useCurrencyFormatter'
import { PlayerBalanceType } from '@/src/services/types'
import { Trans, useTranslation } from 'react-i18next'
import { MdOutlineClose } from 'react-icons/md'

type WalletProps = {
  balance: PlayerBalanceType | undefined
  onClick?: () => void
}

const Wallet = ({ balance, onClick }: WalletProps) => {
  const { t } = useTranslation(['common'])
  const { formatter } = useCurrencyFormatter()

  return (
    <div className='flex h-[100dvh] w-full select-none justify-center'>
      <div>
        <button
          type='button'
          aria-label='close'
          className='absolute right-[1.3rem] top-12 rounded-full bg-white/19 p-1.5 text-sm'
          onClick={onClick}
        >
          <MdOutlineClose size='1.7rem' />
        </button>
        <div className='flex h-full w-full flex-col items-center justify-center'>
          <div>
            <span className={styles.walletheader}>
              <Trans t={t} i18nKey={'balance'}>
                Saldo
              </Trans>
            </span>
            <span className={styles.walletBalance}>
              {formatter.format(balance?.balance || 0)}
            </span>
          </div>
          {balance?.promo != 0 && <PromoArea balance={balance} />}
          <AvailableFreeSpins />
          <WalletButtons onClose={onClick} balance={balance} />
          <LocalizedLink
            href='/my-account/transaction-history'
            className={styles.transactionBtn}
            onClick={onClick}
          >
            {t('show-transaction-history')}
          </LocalizedLink>
        </div>
      </div>
    </div>
  )
}

export default Wallet
