import { api } from '@/src/services/'
import { JackpotType } from '@/src/services/types/jackpot'

const jackpotsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getJackpots: builder.query<JackpotType[], void>({
      query: () => '/jackpots?currency=SEK',
    }),
  }),
  //   overrideExisting: process.env.NODE_ENV === 'development',
  overrideExisting: false,
})

export const { useGetJackpotsQuery } = jackpotsApi

//SSR endpoints
export const { getJackpots } = jackpotsApi.endpoints
export default jackpotsApi
