const SelfTestIcon = ({
  width = '51px',
  height = '45px',
}) => {
  return (
    <svg width={width}
    height={height} viewBox="0 0 45 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>sga-self-test</title>
    <defs>
        <linearGradient x1="111.217434%" y1="50.1517649%" x2="-11.1967105%" y2="50.1517649%" id="linearGradient-1">
            <stop stopColor="#E41913" offset="0%" />
            <stop stopColor="#EA6516" offset="9.74402%" />
            <stop stopColor="#F3A518" offset="21.48%" />
            <stop stopColor="#F9CD1A" offset="31.21%" />
            <stop stopColor="#FDE31A" offset="38.4%" />
            <stop stopColor="#FEEB1A" offset="42.28%" />
            <stop stopColor="#DFDE20" offset="62.31%" />
            <stop stopColor="#009245" offset="100%" />
        </linearGradient>
    </defs>
    <g id="sga-self-test" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-2" transform="translate(1.000000, 1.000000)">
            <g id="Group" fillRule="nonzero">
                <path d="M21.1007905,42.1 C15.6007905,42 10.4007905,39.8 6.50079053,35.9 C2.60079053,31.9 0.500790527,26.7 0.600790527,21.1 C0.700790527,9.8 10.0007905,0.5 21.4007905,0.5 L21.6007905,0.5 C27.1007905,0.6 32.3007905,2.8 36.2007905,6.7 C40.1007905,10.7 42.2007905,15.9 42.1007905,21.5 C42.0007905,32.8 32.7007905,42.1 21.3007905,42.1 L21.1007905,42.1 Z" id="Path" fill="#FFFFFF" />
                <path d="M21.3007905,8.8817842e-16 L21.3007905,1 L21.5007905,1 C26.9007905,1.1 32.0007905,3.2 35.8007905,7.1 C39.6007905,11 41.6007905,16.1 41.6007905,21.5 C41.5007905,32.6 32.4007905,41.6 21.3007905,41.6 L21.1007905,41.6 C15.7007905,41.5 10.6007905,39.4 6.80079053,35.5 C3.00079053,31.6 1.00079053,26.5 1.00079053,21.1 C1.10079053,10 10.2007905,1 21.3007905,1 L21.3007905,8.8817842e-16 M21.3007905,8.8817842e-16 C9.70079053,8.8817842e-16 0.200790527,9.4 0.000790526553,21 C-0.0992094734,32.7 9.30079053,42.4 21.0007905,42.5 C21.1007905,42.5 21.1007905,42.5 21.2007905,42.5 C32.8007905,42.5 42.3007905,33.1 42.5007905,21.5 C42.6007905,9.8 33.2007905,0.1 21.5007905,8.8817842e-16 C21.5007905,8.8817842e-16 21.4007905,8.8817842e-16 21.3007905,8.8817842e-16 L21.3007905,8.8817842e-16 Z" id="Shape" fill="#020203" />
            </g>
            <path d="M36.4007905,21 C34.2007905,2.4 8.60079053,2 6.00079053,21" id="Path" stroke="url(#linearGradient-1)" strokeWidth="6.7742" strokeLinecap="round" />
            <path d="M16.9007905,21.6 L25.7007905,5.8 L23.9007905,23.8 C23.3007905,25.7 21.2007905,26.8 19.3007905,26.2 C17.4007905,25.6 16.3007905,23.5 16.9007905,21.6 Z" id="Path" fill="#1E1E1C" fillRule="nonzero" />
        </g>
    </g>
</svg>
  )
}

export default SelfTestIcon
