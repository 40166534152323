import cn from 'classnames'

type ProgressBarType = {
  percentage: number
  className?: string
}
const ProgressBar = ({ percentage, className }: ProgressBarType) => {
  return (
    <div
      className={cn(
        'rounded-full bg-primary-lighter',
        {
          'mx-12': !className || className.indexOf('mx-') === -1,
        },
        className,
      )}
    >
      <div
        className='h-2 rounded-full bg-secondary'
        style={{ width: `${percentage}%` }}
      />
    </div>
  )
}

export default ProgressBar
