import ProgressBar from '@/src/components/ProgressBar'
import useCurrencyFormatter from '@/src/hooks/useCurrencyFormatter'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type WageringProgressType = {
  used: number
  total: number
}

const WageringProgress = ({ used, total }: WageringProgressType) => {
  const { t } = useTranslation(['my-account', 'common'])

  const amountRemaining = total - used

  const percentage = useMemo(() => {
    return Math.ceil((amountRemaining * 100) / total)
  }, [amountRemaining, total])

  const { formatter } = useCurrencyFormatter({
    minPrecision: 2,
    maxPrecision: 2,
  })

  return (
    <div className='space-y-2 text-center'>
      <h3 className='text-base font-bold tracking-wide text-white opacity-50'>
        {t('common:wagering-progress')}
      </h3>
      {!!total && <ProgressBar percentage={percentage} className='mx-2' />}
      <div className='flex justify-center space-x-2 font-bold opacity-100'>
        <span>{formatter.format(amountRemaining)}</span>
        <span className='opacity-50'>/</span>
        <span className='opacity-50'>{formatter.format(total)}</span>
      </div>
    </div>
  )
}

export default WageringProgress
