import styles from '@/src/components/FormElements/Label/Label.module.scss'
import cn from 'classnames'

export type LabelThemeType = 'light'
type LabelProps = {
  children: React.ReactNode
  theme?: LabelThemeType
  width?: string
}

const Label = ({ children, theme = 'light', width = 'w-full' }: LabelProps) => {
  return (
    <label className={cn(styles.root, styles[theme], width)}>{children}</label>
  )
}

export default Label
