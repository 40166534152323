import LocalizedLink from '@/src/components/LocalizedLink'
import { GameType } from '@/src/services/types'
import { slugify } from '@/src/utils/slugHelper'
import React from 'react'

type Props = {
  game: GameType
  children: React.ReactNode
  className: string
}

function GameLink({ game, children, className }: Props) {
  return (
    <LocalizedLink
      href={`/game/${slugify(game.providerName)}/${game.friendlyName}`}
      key={game.gameId}
      className={className}
      role='button'
    >
      {children}
    </LocalizedLink>
  )
}

export default GameLink
