import { usePathname } from '@/src/hooks/usePathname'
import { usePrismicBonusesUidsQuery } from '@/src/services/nextApi'
import { useGetPaymentMethodsQuery } from '@/src/services/payment'
import { useGetPlayerQuery } from '@/src/services/player'
import { useGetVouchersQuery } from '@/src/services/websocket'
import {
  campaignsBonusTypeData,
  vouchersData,
} from '@/src/utils/helpers/bonusHelper'
import { applyContentToBonuses } from '@/src/utils/prismic'
import { PrismicDocument } from '@prismicio/client'
import { useMemo } from 'react'

const POLLING_PROHIBITED = ['/pnp/deposit', '/pnp/withdraw']

export function useGetBonuses(prismicCampaigns?: PrismicDocument[]) {
  const pathname = usePathname()

  const poll = POLLING_PROHIBITED.reduce((acc, val) => {
    return !pathname.includes(val) && acc
  }, true)

  const { User } = useGetPlayerQuery(undefined, {
    selectFromResult: (result) => ({
      User: result.currentData,
    }),
  })
  const { prismicBonuses } = usePrismicBonusesUidsQuery(undefined, {
    selectFromResult: (result) => ({
      prismicBonuses: result.currentData,
    }),
  })

  const options = {
    skip: !User || !!User?.error,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectFromResult: (result: any) => ({
      data: result.currentData,
    }),
    pollingInterval: 0,
  }
  if (poll) options.pollingInterval = 3000

  const { data: voucherData } = useGetVouchersQuery(
    { data: User },
    { ...options, pollingInterval: 0 },
  )
  const { data: paymentMethodsData } = useGetPaymentMethodsQuery(
    undefined,
    options,
  )

  const bonuses = useMemo(() => {
    if (!voucherData || !paymentMethodsData || !prismicBonuses) return []

    const localBonuses = [
      ...vouchersData(voucherData),
      ...campaignsBonusTypeData(paymentMethodsData),
    ]
      .filter((bonus) =>
        prismicBonuses?.prismicBonusesUids.includes(bonus?.slug || ''),
      )
      .sort(
        (bonus) => (bonus.bonusType === 'campaign' ? -1 : 1), // campaigns on top
      )
    return prismicCampaigns && prismicCampaigns?.length > 0
      ? applyContentToBonuses(localBonuses, prismicCampaigns)
      : localBonuses
  }, [paymentMethodsData, voucherData, prismicBonuses, prismicCampaigns])

  const campaigns = useMemo(() => {
    if (!bonuses) return []
    return bonuses.filter((bonus) => bonus.bonusType === 'campaign')
  }, [bonuses])

  return {
    bonuses,
    campaigns,
  }
}
