import playerApi from '@/src/services/player'
import { store } from '@/src/store/store'
import { Client, IMessage } from '@stomp/stompjs'

const bonusCheckListener = (client: Client) => {
  const createListener = (message: IMessage) => {
    if (message?.body) {
      store.dispatch(playerApi.util.invalidateTags(['Bonuses']))
    }
  }

  const subscription = client.subscribe('/user/queue/bonus', createListener)

  return () => subscription.unsubscribe()
}

const unSubBonusCheckListener = (client: Client) => {
  client.unsubscribe('/user/queue/bonus')
}

export { bonusCheckListener, unSubBonusCheckListener }
