import { LimitsFormattedType } from '@/src/services/types'

export const generateLimits = (limits: LimitsFormattedType | undefined) => {
  const depositLimits = {
    daily: {
      amount: limits?.dailyDeposit?.amount,
      newAmount: limits?.dailyDeposit?.newAmount,
      used: limits?.dailyDeposit?.usedAmount,
      pending: limits?.dailyDeposit?.amountEffectiveTime,
    },
    weekly: {
      amount: limits?.weeklyDeposit?.amount,
      newAmount: limits?.weeklyDeposit?.newAmount,
      used: limits?.weeklyDeposit?.usedAmount,
      pending: limits?.weeklyDeposit?.amountEffectiveTime,
    },
    monthly: {
      amount: limits?.monthlyDeposit?.amount,
      newAmount: limits?.monthlyDeposit?.newAmount,
      used: limits?.monthlyDeposit?.usedAmount,
      pending: limits?.monthlyDeposit?.amountEffectiveTime,
    },
  }
  const lossLimits = {
    daily: {
      amount: limits?.dailyLossLimit?.amount,
      newAmount: limits?.dailyLossLimit?.newAmount,
      used: limits?.dailyLossLimit?.usedAmount,
      pending: limits?.dailyLossLimit?.amountEffectiveTime,
    },
    weekly: {
      amount: limits?.weeklyLossLimit?.amount,
      newAmount: limits?.weeklyLossLimit?.newAmount,
      used: limits?.weeklyLossLimit?.usedAmount,
      pending: limits?.weeklyLossLimit?.amountEffectiveTime,
    },
    monthly: {
      amount: limits?.monthlyLossLimit?.amount,
      newAmount: limits?.monthlyLossLimit?.newAmount,
      used: limits?.monthlyLossLimit?.usedAmount,
      pending: limits?.monthlyLossLimit?.amountEffectiveTime,
    },
  }

  const timeLimits = {
    daily: {
      amount: limits?.dailyTime?.amount
        ? limits?.dailyTime?.amount / 60
        : undefined,
      newAmount: limits?.dailyTime?.newAmount
        ? limits?.dailyTime?.newAmount / 60
        : undefined,
      used: limits?.dailyTime?.usedAmount
        ? parseFloat((limits?.dailyTime?.usedAmount / 3600000).toFixed(2))
        : undefined,
      pending: limits?.dailyTime?.amountEffectiveTime,
    },
    weekly: {
      amount: limits?.weeklyTime?.amount
        ? limits?.weeklyTime?.amount / 60
        : undefined,
      newAmount: limits?.weeklyTime?.newAmount
        ? limits?.weeklyTime?.newAmount / 60
        : undefined,
      used: limits?.weeklyTime?.usedAmount
        ? parseFloat((limits?.weeklyTime?.usedAmount / 3600000).toFixed(2))
        : undefined,
      pending: limits?.weeklyTime?.amountEffectiveTime,
    },
    monthly: {
      amount: limits?.monthlyTime?.amount
        ? limits?.monthlyTime?.amount / 60
        : undefined,
      newAmount: limits?.monthlyTime?.newAmount
        ? limits?.monthlyTime?.newAmount / 60
        : undefined,
      used: limits?.monthlyTime?.usedAmount
        ? parseFloat((limits?.monthlyTime?.usedAmount / 3600000).toFixed(2))
        : undefined,
      pending: limits?.monthlyTime?.amountEffectiveTime,
    },
  }
  const realityCheck = {
    realityCheckLimitAmount:
      limits?.realityCheck?.realityCheckLimitAmount || undefined,
  }

  return { depositLimits, lossLimits, timeLimits, realityCheck }
}
