type CurrenciesType = 'SEK'
type CountriesType = 'SWE'
export type FormatterType = {
  currency?: CurrenciesType
  country?: CountriesType
  minPrecision?: number
  maxPrecision?: number
  options?: Intl.NumberFormatOptions
}

const mapCurrentLanguage = (country: CountriesType = 'SWE') => {
  const languageMapper: Record<CountriesType, string> = {
    SWE: 'sv-SE',
  }
  return languageMapper[country]
}

export const formatter = ({
  currency = 'SEK',
  country = 'SWE',
  minPrecision = 2,
  maxPrecision = 2,
  options = {},
}: FormatterType): Intl.NumberFormat => {
  const language = mapCurrentLanguage(country)
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
    ...options,
  })
}
const currencyFormatter = { formatter }

export default currencyFormatter
