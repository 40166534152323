import classes from '@/src/components/Thumbnail/game.module.scss'
import useCurrencyFormatter from '@/src/hooks/useCurrencyFormatter'
import { JackpotType } from '@/src/services/types/jackpot'
import { useGetFreeSpinsBonusWsQuery } from '@/src/services/websocket.ts'
import { selectIsLoggedIn } from '@/src/store/slices/authSlice'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

type GameThumbnailLabelsType = {
  gameId: number
  newGame: boolean | null
  jackpots?: JackpotType[] | undefined
  showFreeSpinsLabel?: boolean
  hasNameBar?: boolean
}

const GameThumbnailLabels = ({
  gameId,
  newGame,
  jackpots,
  showFreeSpinsLabel = true,
  hasNameBar = false,
}: GameThumbnailLabelsType) => {
  const { formatter } = useCurrencyFormatter({
    minPrecision: 0,
    maxPrecision: 0,
  })

  const isLoggedIn = useSelector(selectIsLoggedIn)
  const { data: availableFreeSpins } = useGetFreeSpinsBonusWsQuery(undefined, {
    skip: !isLoggedIn,
  })

  const [isFreeSpins, setIsFreeSpins] = useState(false)

  useEffect(() => {
    if (isLoggedIn) {
      availableFreeSpins?.bonuses?.transactions?.forEach((transaction) => {
        transaction?.games?.find(
          (game) => game.gameId == gameId && setIsFreeSpins(true),
        )
      })
    }
  }, [availableFreeSpins, gameId, isLoggedIn])

  const [jackpotForGame, setJackpotForGame] = useState('')
  useEffect(() => {
    if (jackpots && jackpots?.length > 0) {
      const result = jackpots.filter(function (jackpot) {
        return jackpot.gameId === gameId
      })[0]

      if (result) {
        setJackpotForGame(formatter.format(result.amount))
      }
    }
  }, [formatter, gameId, jackpots])

  const { t } = useTranslation(['common'])

  return (
    <>
      {newGame && (
        <span
          className={cn(
            classes['thumbnail__label'],
            classes['thumbnail__label--new'],
          )}
        >
          {t('thumbnail.label.new')}
        </span>
      )}
      {isFreeSpins && showFreeSpinsLabel && (
        <span
          data-testid='freespins'
          className={cn(
            classes['thumbnail__label'],
            classes['thumbnail__label--freespins'],
          )}
        >
          {t('thumbnail.label.freespins')}
        </span>
      )}

      {jackpotForGame && (
        <div
          className={cn(
            classes['thumbnail__jackpot'],
            classes['thumbnail__jackpot-value'],
            {
              [classes['thumbnail__jackpot-with-game-name']]: hasNameBar,
            },
          )}
        >
          {jackpotForGame}
        </div>
      )}
    </>
  )
}

export default GameThumbnailLabels
