import styles from '@/src/components/FormElements/Checkbox/Checkbox.module.scss'
import ErrorLabel from '@/src/components/FormElements/ErrorLabel'
import cn from 'classnames'
import { TranslationsType } from 'i18next'
import React from 'react'
import { FieldError } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

type CheckboxCustomProps = {
  message: string
  transdoc?: TranslationsType
  error?: FieldError
  classNameWrapper?: string
  classNameCheckBox?: string
  useTrans?: boolean
  componentsOptions?:
    | readonly React.ReactElement[]
    | { readonly [tagName: string]: React.ReactElement }
}

const Checkbox = React.forwardRef<
  HTMLInputElement,
  InputProps & CheckboxCustomProps
>((props, ref) => {
  const {
    transdoc = 'common',
    classNameCheckBox,
    error,
    classNameWrapper,
    useTrans,
    componentsOptions,
    ...rest
  } = props
  const { t } = useTranslation([transdoc])
  return (
    <>
      <label
        className={cn(
          'flex pt-0 text-sm',
          classNameWrapper,
          styles.checkboxClass,
        )}
      >
        <input
          type='checkbox'
          ref={ref}
          {...rest}
          className={cn(
            `relative mr-3 rounded border-0 bg-white px-2 py-1 text-sm text-white placeholder-black outline-none focus:outline-none focus:ring-0`,
            classNameCheckBox,
          )}
        />

        {!useTrans && (
          <span className='ml-2 opacity-70'>
            {t(props.message as 'common:go-back')}
          </span>
        )}
        {useTrans && (
          <div className={styles.transWrapper}>
            <Trans
              components={componentsOptions}
              i18nKey={props.message as 'label.ageVerified'}
              ns={'onboarding'}
            />
          </div>
        )}
      </label>
      {error && (
        <ErrorLabel
          standalone={true}
          transdoc={transdoc}
          error={error?.message}
        />
      )}
    </>
  )
})

Checkbox.displayName = 'Input'

export default Checkbox
