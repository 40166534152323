'use client'
import ErrorLabel from '@/src/components/FormElements/ErrorLabel'
import styles from '@/src/components/FormElements/Input/Input.module.scss'
import InputPasswordToggleButton from '@/src/components/FormElements/Input/InputPasswordToggleButton'
import Label, {
  LabelThemeType,
} from '@/src/components/FormElements/Label/index.tsx'
import Iconphone from '@/src/components/SvgIcons/IconPhone'
import cn from 'classnames'
import { TranslationsArr, TranslationsType } from 'i18next'
import React from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BsCheckCircle } from 'react-icons/bs'

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

type InputCustomProps = {
  label?: string
  label2?: string
  labelTheme?: LabelThemeType
  error?: FieldError
  theme?: 'light' | 'dark' | 'custom'
  inputsize?: 'small' | 'default' | 'large'
  className?: string
  classwrapper?: string
  transdoc?: TranslationsType | TranslationsArr
  type?: string
  verified?: boolean
  showPasswordRule?: boolean
  phoneNumberInput?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps & InputCustomProps>(
  (props, ref) => {
    const {
      error,
      theme = 'light',
      inputsize = 'default',
      className,
      transdoc = 'common',
      classwrapper,
      label,
      label2,
      labelTheme = 'light',
      type = 'text',
      hidden,
      disabled,
      verified = false,
      showPasswordRule = false,
      phoneNumberInput = false,
      ...rest
    } = props
    const { t } = useTranslation(
      Array.isArray(transdoc) ? transdoc : [transdoc],
    )

    return (
      <div
        className={cn('w-full', classwrapper, {
          hidden: hidden,
        })}
      >
        <div className='flex items-center'>
          {label && (
            <Label theme={labelTheme} width='w-fit'>
              {t(label, { defaultValue: label })}
            </Label>
          )}
          {label2 && (
            <label className={styles.label2}>
              {t(label2, { defaultValue: label2 })}
            </label>
          )}
        </div>

        <div
          className={cn('relative', theme, {
            [styles.error]: !!error,
            hidden: type === 'hidden' || hidden,
          })}
        >
          <div className={`${phoneNumberInput && 'flex flex-row'}`}>
            {phoneNumberInput && (
              <div className='relative'>
                <Iconphone classname='absolute z-20 top-[13px] left-[6px]' />
                <select
                  disabled
                  className='mr-2 h-full appearance-none rounded-md !bg-none p-2 pl-7 text-xl font-semibold text-black/30'
                >
                  <option defaultValue='1' value='1'>
                    {t('region-phone-prefix')}
                  </option>
                </select>
              </div>
            )}
            <input
              autoComplete='off'
              type={type}
              ref={ref}
              aria-invalid={!!error}
              disabled={disabled}
              className={cn(
                styles[theme],
                {
                  'h-[60px] text-2xl font-bold placeholder:font-semibold':
                    inputsize === 'large',
                  'h-12 text-xl': inputsize !== 'large',
                },
                `relative w-full rounded-md border-0 bg-white px-4 py-1 outline-none focus:outline-none`,
                { [styles.error]: !!error },
                { [styles.disabled]: disabled },
                className,
              )}
              {...rest}
            />
          </div>

          {verified && <BsCheckCircle className={styles.verified} />}
          {type === 'password' && props.name && (
            <InputPasswordToggleButton name={props.name} {...props} />
          )}
        </div>
        <ErrorLabel
          standalone={props.type === 'hidden'}
          transdoc={transdoc}
          error={error?.message}
        />
        {showPasswordRule && (
          <span className='block w-full pt-1 text-left text-white opacity-70'>
            {t('password-rule')}
          </span>
        )}
      </div>
    )
  },
)

Input.displayName = 'Input'

export default Input
