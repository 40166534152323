type IconPhoneProps = {
  width?: string
  height?: string
  classname?: string
}

const Iconphone = ({
  width = '20',
  height = '21',
  classname,
}: IconPhoneProps) => (
  <div className={classname}>
    <svg
      id='Untitled-Page%201'
      viewBox='0 0 24 44'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={width}
      height={height}
      style={{ opacity: '0.30' }}
    >
      <g id='Layer%201'>
        <path
          id='Phone'
          d='M 20.002 44 L 3.998 44 C 1.7894 44 0 42.1537 0 39.8749 L 0 4.125 C 0 1.8463 1.7894 0 3.998 0 L 20.002 0 C 22.2106 0 24 1.8463 24 4.125 L 24 39.8749 C 24 42.1537 22.2106 44 20.002 44 ZM 21.3347 4.125 C 21.3347 3.3663 20.7373 2.75 20.002 2.75 L 3.998 2.75 C 3.2627 2.75 2.6653 3.3663 2.6653 4.125 L 2.6653 39.8749 C 2.6653 40.6337 3.2627 41.25 3.998 41.25 L 20.002 41.25 C 20.7373 41.25 21.3347 40.6337 21.3347 39.8749 L 21.3347 4.125 ZM 14.6672 6.875 L 9.3364 6.875 C 8.6003 6.875 8.0038 6.2594 8.0038 5.5 C 8.0038 4.7406 8.6003 4.125 9.3364 4.125 L 14.6672 4.125 C 15.4031 4.125 15.9999 4.7406 15.9999 5.5 C 15.9999 6.2594 15.4031 6.875 14.6672 6.875 ZM 12.0017 34.3824 C 13.4708 34.3824 14.6606 35.6112 14.6606 37.1246 C 14.6606 38.639 13.4708 39.8679 12.0017 39.8679 C 10.5328 39.8679 9.3429 38.639 9.3429 37.1246 C 9.3429 35.6112 10.5328 34.3824 12.0017 34.3824 Z'
        />
      </g>
    </svg>
  </div>
)

export default Iconphone
