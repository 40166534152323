import { Input } from '@/src/components/FormElements'
import cn from 'classnames'
import { ChangeEvent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

type SearchInputProps = {
  onSearch: (_e: ChangeEvent<HTMLInputElement>) => void
  className?: string
  focusSearch: boolean
  defaultValue: string
}
const SearchInput = ({
  onSearch,
  className,
  focusSearch,
  defaultValue,
}: SearchInputProps) => {
  const { t } = useTranslation(['common'])
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (focusSearch) {
      inputRef?.current?.focus()
    }
  }, [focusSearch])

  return (
    <div
      className={cn(
        'relative flex w-full flex-initial flex-col md:px-0',
        className,
      )}
    >
      <Input
        id={'search-input'}
        defaultValue={defaultValue}
        ref={inputRef}
        placeholder={t('search-games-placeholder')}
        className='sticky top-16 flex-1'
        type='search'
        autoComplete='none'
        onFocus={() => window.scrollTo(0, 0)}
        onChange={onSearch}
      />
    </div>
  )
}

export default SearchInput
