import { AnnouncementsType } from '@/src/services/types/announcements'

import { Client, IMessage } from '@stomp/stompjs'

const announcementsListeners = (
  client: Client,
  updateCachedData: (
    _updateFunction: (_draft: AnnouncementsType[]) => void,
  ) => void,
) => {
  const updateListener = (message: IMessage) => {
    if (message.body) {
      // Title and description encoded on update
      const data = JSON.parse(decodeURIComponent(message.body))
      updateCachedData((draft: AnnouncementsType[]) => {
        delete data.type
        delete data.includeCountry
        const newValues = draft.map((d) => {
          if (d.id === data.id) {
            return data
          }
          return d
        })
        return newValues
      })
    }
  }

  const deleteListener = (message: IMessage) => {
    if (message.body) {
      const data = JSON.parse(message.body)
      updateCachedData((draft) => {
        // Billfold sending id as a sting here
        return [...draft.filter((d) => d.id !== +data.id)]
      })
    }
  }

  const createListener = (message: IMessage) => {
    if (message.body) {
      const data = JSON.parse(decodeURIComponent(message.body))
      updateCachedData((draft: AnnouncementsType[]) => {
        return [...draft, data]
      })
    }
  }

  const updatedSubscription = client.subscribe(
    '/topic/announcement-updated',
    updateListener,
  )
  const removedSubscription = client.subscribe(
    '/topic/announcement-removed',
    deleteListener,
  )
  const createdSubscription = client.subscribe(
    '/topic/announcement-created',
    createListener,
  )

  return () => {
    updatedSubscription.unsubscribe()
    removedSubscription.unsubscribe()
    createdSubscription.unsubscribe()
  }
}

export { announcementsListeners }
