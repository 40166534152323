import { api } from '@/src/services/'
import {
  FamilyType,
  GamesGroupType,
  GameType,
  PlayGameType,
  PositioningGroupsType,
  PositioningGroupType,
  PositioningGroupTypeFromApi,
  PositioningProviderGamesType,
  PositioningProvidersType,
  PositioniningType,
  PreLiveType,
} from '@/src/services/types/games.tsx'
import { getFormData } from '@/src/services/utils/index.tsx'
import {
  filterFamilies,
  filterProviderGames,
  transformProviders,
} from '@/src/utils/serializers/gameFamilyData'

type CountryCodes = {
  country?: 'FIN' | 'SWE' | 'NOR' | 'DEU' | 'CAN' | 'NZL' | 'IND'
}
const COUNTRY_CODE_PROJECT = 'SWE'

const gamesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRecentGames: builder.query<FamilyType[], void>({
      query: () => `/game/families/recent`,
      providesTags: ['RecentGame'],
    }),
    getFavouriteGames: builder.query<{ games: GameType[] }, void>({
      query: () => `/games/favourite`,
      providesTags: ['FavouriteGame'],
    }),
    addFavouriteGame: builder.mutation<void, number>({
      query: (gameId) => ({
        url: '/games/favourites/add',
        method: 'POST',
        body: getFormData({ gameId }),
      }),
      invalidatesTags: ['FavouriteGame'],
    }),
    removeFavouriteGame: builder.mutation<void, number>({
      query: (gameId) => ({
        url: '/games/favourites/remove',
        method: 'POST',
        body: getFormData({ gameId }),
      }),
      invalidatesTags: ['FavouriteGame'],
    }),
    getGamesByGroup: builder.query<
      GamesGroupType,
      string | string[] | undefined
    >({
      query: (group) => `/games/${group}`,
    }),
    playGame: builder.mutation<
      PlayGameType,
      {
        mode: string
        name: string
        feSessionId?: string
        feProfileSessionId?: string
      }
    >({
      query: ({
        mode,
        name,
        feSessionId = undefined,
        feProfileSessionId = undefined,
      }) => ({
        url: `/game/${mode}/${name}`,
        method: 'GET',
        headers: {
          'x-feSessionId': feSessionId,
          'x-feProfileSessionId': feProfileSessionId,
        },
      }),
      invalidatesTags: [
        'RecentGame',
        'GameTransactions',
        'Bonuses',
        'PromoTransactions',
        'Limits',
      ],
    }),

    getPlayGame: builder.query<
      PlayGameType,
      { mode: 'demo' | 'real'; name: string }
    >({
      query: ({ mode, name }) => `/game/${mode}/${name}`,
    }),
    //
    // New api testing
    //
    getPositioning: builder.query<
      PositioniningType,
      { size: number } & CountryCodes
    >({
      query: ({ size = 20, country = COUNTRY_CODE_PROJECT }) =>
        `/positioning?countryCode=${country}&size=${size}`,
      keepUnusedDataFor: 3600,
      providesTags: ['GameSearch'],
      transformResponse: (response: PositioniningType) => {
        response.positioning = response?.positioning?.flatMap(
          (group: PositioningGroupType) => {
            return filterFamilies(group)
          },
        )
        return response
      },
    }),
    getPositioningGroups: builder.query<PositioningGroupsType, CountryCodes>({
      query: ({ country = COUNTRY_CODE_PROJECT }) =>
        `/positioning/groups?countryCode=${country}`,
    }),
    getPositioningGroup: builder.query<
      PositioningGroupType,
      {
        group: string
        size?: number
        page?: number
        providers?: number[]
      } & CountryCodes
    >({
      query: ({
        group,
        size = 10,
        page = 1,
        providers = [],
        country = COUNTRY_CODE_PROJECT,
      }) =>
        `/positioning/${group}?size=${size}&page=${page}&provider=${providers.join(',')}&countryCode=${country}`,
      transformResponse: (response: PositioningGroupTypeFromApi) => {
        const transformedGroup = transformProviders(response)
        return filterFamilies(transformedGroup)
      },
    }),
    getPositioningProviders: builder.query<PositioningProvidersType, void>({
      query: () => `/game/providers?active=true`,
    }),
    getPreLive: builder.query<PreLiveType, { type: 'demo' | 'real' }>({
      query: ({ type }) => `/game/${type}/BIAPreLive?t=${Date.now()}`,
      keepUnusedDataFor: 0,
    }),
    getPositioningProviderGames: builder.query<
      PositioningProviderGamesType,
      {
        providerId: number
        size?: number
        page?: number | string
        supplierEnabled?: boolean
      } & CountryCodes
    >({
      query: ({
        providerId,
        size = 10,
        page = 1,
        supplierEnabled = false,
        country = COUNTRY_CODE_PROJECT,
      }) =>
        `/${providerId}/games?countryCode=${country}&page=${page}&size=${size}&supplier=${supplierEnabled}`,
      transformResponse: (response: PositioningProviderGamesType) => {
        response.games = filterProviderGames(response)
        return response
      },
    }),
    getPositioningGame: builder.query<
      FamilyType,
      { slug: string; providerId: number }
    >({
      query: ({ slug, providerId }) => `/positioning/${slug}/${providerId}`,
    }),
  }),
  overrideExisting: process.env.NODE_ENV === 'development',
})
///positioning/{slug}/{providerId}
export const {
  useGetRecentGamesQuery,
  useGetFavouriteGamesQuery,
  useAddFavouriteGameMutation,
  useRemoveFavouriteGameMutation,
  usePlayGameMutation,
  useGetPlayGameQuery,
  useGetPositioningQuery,
  useGetPositioningProvidersQuery,
  useGetPositioningProviderGamesQuery,
  useGetPositioningGroupQuery,
  useGetPreLiveQuery,
  useGetPositioningGameQuery,
  usePrefetch,
} = gamesApi

//SSR endpoints
export const {
  getPositioning,
  getPlayGame,
  getGamesByGroup,
  getPositioningGroups,
  getPositioningGroup,
  getPositioningProviders,
  getPositioningProviderGames,
  getPositioningGame,
  getPreLive,
} = gamesApi.endpoints
export default gamesApi
