import { SVGProps } from 'react'

type HeartIconProps = {
  active?: boolean
}

const HeartIcon = ({
  active = false,
  fill = 'var(--secondary)',
  stroke = 'var(--secondary-dark)',
  width = '19',
  height = '17',
}: HeartIconProps & SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 43 40'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width={width}
    height={height}
  >
    <path
      id='InsideHeart'
      d='m 12.0698,2.7832 c 1.315,0 3.6921,0.6498 7.548,4.5703 l 1.9115,1.9434 1.8828,-1.9746 c 2.6771,-2.808 5.5257,-4.5528 7.4341,-4.5528 2.5735,0 4.6024,0.909 6.5802,2.9469 1.8637,1.9203 2.8905,4.4728 2.8905,7.1885 0.0014,2.7169 -1.0255,5.2721 -2.9089,7.2129 -0.1533,0.1609 -8.2553,9.1865 -15.0443,16.7516 -0.3189,0.3138 -0.6857,0.3608 -0.8789,0.3608 -0.1918,0 -0.5534,-0.0459 -0.8716,-0.35 C 18.959,34.9801 6.969,21.2145 5.5747,19.7761 3.7091,17.8539 2.6822,15.2992 2.6822,12.5824 2.6829,9.8668 3.7097,7.3142 5.576,5.3926 7.3908,3.5203 9.5151,2.7832 12.0698,2.7832 m 9.4301,2.5964 C 0,0 0,0 21.4999,5.3796 Z'
      fill={fill}
      opacity={active ? 1 : 0.1}
    />
    <path
      id='Heart'
      d='m 12.0698,2.7832 c 1.315,0 3.6921,0.6498 7.548,4.5703 l 1.9115,1.9434 1.8828,-1.9746 c 2.6771,-2.808 5.5257,-4.5528 7.4341,-4.5528 2.5735,0 4.6024,0.909 6.5802,2.9469 1.8637,1.9203 2.8905,4.4728 2.8905,7.1885 0.0014,2.7169 -1.0255,5.2721 -2.9089,7.2129 -0.1533,0.1609 -8.2553,9.1865 -15.0443,16.7516 -0.3189,0.3138 -0.6857,0.3608 -0.8789,0.3608 -0.1918,0 -0.5534,-0.0459 -0.8716,-0.35 C 18.959,34.9801 6.969,21.2145 5.5747,19.7761 3.7091,17.8539 2.6822,15.2992 2.6822,12.5824 2.6829,9.8668 3.7097,7.3142 5.576,5.3926 7.3908,3.5203 9.5151,2.7832 12.0698,2.7832 m 0,-2.7695 c -3.2075,0 -6.0353,0.9905 -8.3896,3.4193 -4.9069,5.053 -4.9069,13.2474 0,18.3033 1.4007,1.446 14.9985,17.0642 14.9985,17.0642 0.7747,0.8007 1.7918,1.1995 2.8061,1.1995 1.0144,0 2.0295,-0.3988 2.8036,-1.1995 0,0 14.8734,-16.5745 15.0318,-16.7407 4.9064,-5.0565 4.9064,-13.2512 0,-18.3043 C 36.8854,1.2455 34.1637,0 30.8462,0 27.6765,0 24.1403,2.6101 21.4999,5.3796 18.7756,2.6101 15.3493,0.0137 12.0698,0.0137 Z'
      stroke={active ? fill : stroke}
      fill={active ? fill : stroke}
      strokeWidth={active ? 1 : 0}
      opacity={active ? 1 : 0.5}
    />
  </svg>
)

export default HeartIcon
