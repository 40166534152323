'use client'

import { RoundedButton } from '@/src/components/Button'
import styles from '@/src/components/NavBar/Wallet/WalletButton.module.scss'
import Wallet from '@/src/components/NavBar/Wallet/index.tsx'
import SlideMenu from '@/src/components/SlideMenu/SlideMenu'
import SnackBar from '@/src/components/SnackBar'
import useRouter from '@/src/hooks/Router/useRouter'
import useAuthGuard from '@/src/hooks/useAuthGuard'
import useCurrencyFormatter from '@/src/hooks/useCurrencyFormatter'
import { useGetBalanceQuery } from '@/src/services/player'
import { ThumbnailEssentials } from '@/src/services/types/games'
import { useGetFreeSpinsBonusWsQuery } from '@/src/services/websocket.ts'
import {
  selectDepositWithBonus,
  setDepositWithBonus,
} from '@/src/store/slices/navigationSlice.tsx'
import cn from 'classnames'
import get from 'lodash/get'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImSpinner11 } from 'react-icons/im'
import { useDispatch, useSelector } from 'react-redux'

type WalletButtonProps = {
  isWalletOpen: boolean
  className?: string
  onClick?: () => void
  size?: 'small' | 'tiny' | 'large'
  snackBarInstance?: boolean
  dataAutomationId?: string
}

function transformGameName(str) {
  const words = str.replaceAll('-', ' ').split(' ')
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
  }
  return words.join(' ')
}

const WalletButton = ({
  isWalletOpen,
  className,
  size,
  onClick,
  snackBarInstance = false,
  dataAutomationId,
}: WalletButtonProps) => {
  const [isSldeOpen, toggleSlide] = useState<boolean | null>(null)
  const { isLoggedIn } = useAuthGuard()
  const { balance } = useGetBalanceQuery(undefined, {
    pollingInterval: 5000,
    skip: !isLoggedIn,
    selectFromResult: ({ data }) => ({
      balance: data,
    }),
  })

  const { data: freeSpins } = useGetFreeSpinsBonusWsQuery(undefined, {
    skip: !isLoggedIn,
  })

  const router = useRouter()
  const { t } = useTranslation(['common', 'bonus'])
  const { formatter } = useCurrencyFormatter()
  const [label, setLabel] = useState<string>(
    balance?.balance ? formatter.format(balance.balance) : t('play'),
  )
  const hasFreeSpins = (freeSpins?.bonuses?.transactions || []).length > 0
  const depositWithBonus = useSelector(selectDepositWithBonus)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (get(balance, 'balance') !== undefined) {
      setLabel(formatter.format(balance?.balance || 0))
    } else {
      setLabel(t('play'))
    }
  }, [balance, formatter, t])

  const freeSpinGame = freeSpins?.bonuses?.transactions[0]?.games[0] || null

  useEffect(() => {
    if (depositWithBonus && snackBarInstance && freeSpinGame) {
      setShowSnackBar(true)
      setTimeout(() => {
        setShowSnackBar(false)
        dispatch(setDepositWithBonus(false))
      }, 3000)
    }
  }, [depositWithBonus, snackBarInstance, dispatch, freeSpinGame])

  const btnHandler = () => {
    if (!isLoggedIn) {
      return router.push('/pnp/deposit')
    }

    if (typeof onClick === 'function') {
      onClick()
    }

    toggleSlide(true)
  }

  let game: ThumbnailEssentials | undefined
  if (freeSpinGame) {
    game = {
      name: freeSpinGame.name,
      provider: freeSpinGame.provider,
      newGame: freeSpinGame.newGame,
      gameId: freeSpinGame.gameId,
    }
  }

  return (
    <>
      <RoundedButton
        size={size ? size : (balance?.balance || 0) > 99999 ? 'tiny' : 'small'}
        onClick={btnHandler}
        disabled={isWalletOpen}
        className={`m-0 ${cn(className)}`}
      >
        <span data-automation-id={dataAutomationId}>{label}</span>
        {hasFreeSpins && (
          <span
            className={cn(
              `${styles.freespinBadgeShadow} absolute -right-2 -top-2 z-10 flex h-4 w-4 items-center justify-center rounded-full border-2 border-transparent bg-success text-xs font-semibold leading-5`,
              { 'h-4 w-4': size === 'small' },
              { 'h-5 w-5': size === 'large' },
            )}
          >
            <ImSpinner11 color='white' size={size === 'small' ? 8 : 10} />
          </span>
        )}
      </RoundedButton>

      {isLoggedIn && (
        <>
          {snackBarInstance && (
            <SnackBar showSnackbar={showSnackBar}>
              <>
                {freeSpinGame && game != undefined && (
                  <>
                    <span className='flex flex-col'>
                      <span className='text-sm'>
                        {t('bonus:snack-bar-freespins.success')}
                      </span>
                      <span className='text-sm'>
                        {t('bonus:snack-bar-freespins.message', {
                          amount: freeSpins?.bonuses.transactions[0].freeSpins,
                          game: transformGameName(
                            freeSpins?.bonuses.transactions[0].games[0]
                              .friendlyName,
                          ),
                        })}
                      </span>
                    </span>
                  </>
                )}
              </>
            </SnackBar>
          )}
          <SlideMenu
            directions='left'
            isOpen={isSldeOpen}
            onClose={() => toggleSlide(false)}
          >
            <Wallet balance={balance} onClick={() => toggleSlide(false)} />
          </SlideMenu>
        </>
      )}
    </>
  )
}

export default WalletButton
