import Overlay from '@/src/components/Overlay'
import cn from 'classnames'

type SlideMenuProps = {
  directions: string
  children: React.ReactNode
  classNames?: string
  isOpen: boolean | null
  onClose: () => void
}

const SlideMenu = ({
  directions,
  children,
  isOpen,
  onClose,
}: SlideMenuProps) => {
  return (
    <>
      {isOpen && <Overlay onClick={() => onClose()} />}
      <div
        className={cn(
          'fixed top-0 z-50 h-screen w-80 overflow-y-auto bg-primary',
          directions === 'right'
            ? {
                'left-0 animate-openmenu': isOpen === true,
                '-left-80 animate-closemenu': isOpen === false,
                'no-animate -left-80': isOpen === null,
              }
            : {
                'right-0 animate-openmenuleft': isOpen === true,
                '-right-80 animate-closemenuleft': isOpen === false,
                'no-animate -right-80': isOpen === null,
              },
        )}
      >
        {children}
      </div>
    </>
  )
}

export default SlideMenu
