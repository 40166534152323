import styles from '@/src/components/FormElements/Input/Input.module.scss'
import classnames from 'classnames'
import { useState } from 'react'
import { FaEye } from 'react-icons/fa'

function InputPasswordToggleButton({ name }: { name: string }) {
  const [showPassword, setShowPassword] = useState(false)

  const togglePassword = (name: string, state: boolean) => {
    const input = document.querySelector(
      `input[name=${name}]`,
    ) as HTMLInputElement

    if (input) {
      input.type = state ? 'text' : 'password'
      setShowPassword(state)
    }
  }

  return (
    <button
      type='button'
      name={`passwordToggle${name}`}
      onClick={() => togglePassword(name, !showPassword)}
      className={classnames(styles.password, {
        [styles.showPassword]: showPassword,
      })}
    >
      <FaEye color='inherit' />
    </button>
  )
}

export default InputPasswordToggleButton
