import styles from '@/src/components/NotificationIcon/NotificationIcon.module.scss'
import classNames from 'classnames'

type NotificationIcon = {
  className?: string
  classModal?: string
  type?: 'unSelected' | 'border' | 'promo'
  value: number | undefined
  style?: React.CSSProperties
  animation?: boolean
  onClick?: (_event: React.MouseEvent<HTMLElement>) => void
}

const NotificationIcon = ({
  className,
  type,
  style,
  value = 0,
  animation = false,
  onClick,
}: NotificationIcon) => {
  const maxValue = 99

  if (value === 0 || value === undefined) {
    return null
  }

  return (
    <div
      style={style}
      className={classNames(
        styles.badge,
        { [styles.dynamic]: value > 9 },
        styles[`${type}`],
        className,
      )}
      onClick={onClick}
    >
      <span className={classNames(styles[`number`])}>
        {animation && <span className={styles.animation} />}
        <span className={styles.value}>
          {value > maxValue ? `${maxValue}+` : value}
        </span>
      </span>
    </div>
  )
}

export default NotificationIcon
