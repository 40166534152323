import useCurrencyFormatter from '@/src/hooks/useCurrencyFormatter.ts'
import { useMemo } from 'react'

const UsePartsSymbol = () => {
  const { formatter } = useCurrencyFormatter({
    options: {
      style: 'decimal',
    },
    maxPrecision: 2,
    minPrecision: 0,
  })

  const partsSymbol = useMemo(() => {
    let symbol = formatter.formatToParts(1.23)
    symbol = symbol.filter((p) => p.type === 'decimal')

    return symbol[0].value
  }, [formatter])

  return { partsSymbol }
}

export default UsePartsSymbol
