import { GameType, PositioningProvidersType } from '@/src/services/types'
import { slugify } from '@/src/utils/slugHelper'

const getGamePath = (game: GameType, providers: PositioningProvidersType) => {
  const path = `/game/${slugify(getProviderName(game.provider, providers))}/${
    game.friendlyName
  }`
  return path
}

const getProviderName = (
  providerId: number,
  providers: PositioningProvidersType,
) => {
  if (Array.isArray(providers)) {
    return providers?.find((provider) => provider.providerId === providerId)
      ?.name
  }
  return ''
}

export { getGamePath, getProviderName }
