import throttle from 'lodash/throttle'
import { useEffect, useState } from 'react'

function useWindowScroll() {
  const [windowScroll, setWindowScroll] = useState({
    x: 0,
    y: 0,
  })

  useEffect(() => {
    let isMounted = true

    function handleScroll() {
      if (isMounted) {
        setWindowScroll({
          x: window.scrollX,
          y: window.scrollY,
        })
      }
    }

    const throttledHandleScroll = throttle(handleScroll, 100)

    window.addEventListener('scroll', throttledHandleScroll, { passive: true })

    handleScroll() // Initial update

    return () => {
      isMounted = false
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [])

  return windowScroll
}

export default useWindowScroll
