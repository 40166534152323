import CounterNotification from '@/src/components/NotificationIcon/CounterNotification'
import { useGetBonuses } from '@/src/hooks/useGetBonuses'
import useMessage from '@/src/hooks/useMessages'

type NotificationIcon = {
  className?: string
  type?: 'unSelected' | 'border' | 'promo'
  onClick?: (_event: React.MouseEvent<HTMLElement>) => void
}

const TotalNotification = ({ className, type, onClick }: NotificationIcon) => {
  const { unreadMessages } = useMessage()
  const { bonuses } = useGetBonuses()

  const totalNotifications = unreadMessages + bonuses.length

  return (
    <CounterNotification
      value={totalNotifications}
      className={className}
      type={type}
      onClick={onClick}
      animation={bonuses.length === 0}
    />
  )
}

export default TotalNotification
