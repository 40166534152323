export type DateFormatterType = {
  locale?: 'fi' | 'swe' | 'en-SE' | 'fi-FI' | 'en-US' | 'sv-SE'
  options?: Intl.DateTimeFormatOptions
}

const dateFormatter = ({
  locale = 'sv-SE',
  options = {},
}: DateFormatterType) => {
  const defaultOpts: Intl.DateTimeFormatOptions = {}
  return new Intl.DateTimeFormat(locale, { ...defaultOpts, ...options })
}

export default dateFormatter
