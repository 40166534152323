const SpelpausIcon = ({ width = '45px', height = '45px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 45 45'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>sga-spelpaus</title>
      <g
        id='sga-spelpaus'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M43.6,22.3 C43.6,34 34.1,43.5 22.3,43.5 C10.6,43.5 1,34 1,22.2 C1.1,10.5 10.6,1 22.3,1 C34.1,1 43.6,10.5 43.6,22.3 Z'
          id='Path'
          fill='#E20D18'
          fillRule='nonzero'
        />
        <path
          d='M19,33 L12.8,33 C12.3,33 11.9,32.6 11.9,32.1 L11.9,12.4 C11.9,11.9 12.3,11.5 12.8,11.5 L19,11.5 C19.5,11.5 19.9,11.9 19.9,12.4 L19.9,32.1 C19.9,32.6 19.5,33 19,33 Z'
          id='Path'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
        <path
          d='M31.8,33.1 L25.6,33.1 C25.1,33.1 24.7,32.7 24.7,32.2 L24.7,12.4 C24.7,11.9 25.1,11.5 25.6,11.5 L31.8,11.5 C32.3,11.5 32.7,11.9 32.7,12.4 L32.7,32.1 C32.7,32.7 32.3,33.1 31.8,33.1 Z'
          id='Path'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}

export default SpelpausIcon
