export const generateGameImagePath = (
  providerId: number,
  name: string,
): string => {
  const path = `${process.env.NEXT_PUBLIC_ASSETS}/images/games/provider-id-${providerId}/${name}.jpg`
  return path
}

export const generateProviderImagePath = (providerId: number): string => {
  return `${process.env.NEXT_PUBLIC_ASSETS}/images/providers/provider-id-${providerId}.svg`
}

export const generateVideoPath = (video: string): string => {
  return `${process.env.NEXT_PUBLIC_ASSETS}/videos/${video}`
}
