const formatCurrencyToNumber = (value: string) => {
  return value.replaceAll(/[^0-9\,\.]+/g, '').replace(/[,]/g, '.')
}

const formatDateWithOrdinalSuffix = (dt: Date) => {
  return (
    dt.getDate() +
    (dt.getDate() % 10 == 1 && dt.getDate() != 11
      ? 'st'
      : dt.getDate() % 10 == 2 && dt.getDate() != 12
        ? 'nd'
        : dt.getDate() % 10 == 3 && dt.getDate() != 13
          ? 'rd'
          : 'th')
  )
}

const formatPhoneNumber = (
  value: string,
  options?: {
    withPrefix: boolean
  },
) => {
  const prefix = '+46'
  const removePrefix = value.replace(prefix, '')
  const phoneNumber = removePrefix.replace(/\D/g, '')

  if (!phoneNumber) return ''
  const regionCodeDetected = phoneNumber.slice(0, 3)[0] === '0'
  let phone = ''
  if (regionCodeDetected) {
    phone = `${phoneNumber.slice(1, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 10)}`
  } else {
    phone = `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 5)} ${phoneNumber.slice(5, 9)}`
  }

  if (options?.withPrefix) {
    return `${prefix} ${phone}`.trim()
  }
  return phone.trim()
}

export {
  formatCurrencyToNumber,
  formatDateWithOrdinalSuffix,
  formatPhoneNumber,
}
