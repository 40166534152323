import WageringProgress from '@/src/components/NavBar/Wallet/WageringProgress'
import styles from '@/src/components/NavBar/Wallet/Wallet.module.scss'
import useCurrencyFormatter from '@/src/hooks/useCurrencyFormatter'
import { PlayerBalanceType } from '@/src/services/types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

type PromoAreaProps = {
  balance: PlayerBalanceType | undefined
}

const PromoArea = ({ balance }: PromoAreaProps) => {
  const { t } = useTranslation(['common', 'bonus'])
  const { formatter } = useCurrencyFormatter({
    currency: 'SEK',
    country: 'SWE',
  })

  return (
    <div className='flex w-full flex-col space-y-5'>
      <div className='my-2 flex w-full justify-evenly'>
        <div className='text-center'>
          <span className='mr-1.5 uppercase opacity-[0.35]'>
            {t('cash-string')}
          </span>
          <span>{formatter.format(balance?.cash || 0)}</span>
        </div>
        <div className={cn(styles.rightBorder, 'mx-2 text-center')} />
        <div className='text-center'>
          <span className='mr-1.5 uppercase opacity-[0.35]'>{t('bonus')}</span>
          <span>{formatter.format(balance?.promo || 0)}</span>
        </div>
      </div>
      <WageringProgress
        used={balance?.currentTurnover || 0}
        total={balance?.totalTurnover || 0}
      />
    </div>
  )
}

export default PromoArea
