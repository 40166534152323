import GameListItem from '@/src/components/GameList/GameListItem'
import styles from '@/src/components/NavBar/Wallet/Wallet.module.scss'
import { SimpleSliderFull } from '@/src/components/SimpleSlider/SimpleSlider'
import { useGetPositioningProvidersQuery } from '@/src/services/games'
import { useGetFreeSpinsBonusWsQuery } from '@/src/services/websocket.ts'
import { selectIsLoggedIn } from '@/src/store/slices/authSlice'
import freeSpinsHelper from '@/src/utils/helpers/freeSpinsHelper'
import cn from 'classnames'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const AvailableFreeSpins = () => {
  const { t } = useTranslation(['common'])
  const isLoggedIn = useSelector(selectIsLoggedIn)

  const { data: availableFreeSpins } = useGetFreeSpinsBonusWsQuery(undefined, {
    skip: !isLoggedIn,
  })

  const { data: providers = [] } = useGetPositioningProvidersQuery(undefined, {
    skip: !isLoggedIn,
  })

  const freeSpinsGames = useMemo(() => {
    if (availableFreeSpins && providers) {
      return freeSpinsHelper(availableFreeSpins, providers)
    }
    return []
  }, [availableFreeSpins, providers])

  const carouselItems = useMemo(
    () =>
      freeSpinsGames &&
      freeSpinsGames?.map((game) => (
        <GameListItem key={game.gameId} game={game} sliderOverrideCss />
      )),
    [freeSpinsGames],
  )

  return (
    <>
      {carouselItems?.length > 0 && (
        <div className='mb-0 mt-6 flex w-full flex-col text-center'>
          <span className={cn('flex items-center', styles.promoAreaHeader)}>
            {t('activeFreeSpins')}
          </span>
          <SimpleSliderFull
            items={carouselItems}
            games={freeSpinsGames}
            override='overSetMaxWidth'
            showButtons='auto'
          />
        </div>
      )}
    </>
  )
}

export default AvailableFreeSpins
