import { PlayerBalanceType } from '@/src/services/types'
import { WsBalanceType } from '@/src/services/types/websocket'
import { addBreadcrumb } from '@sentry/nextjs'
import { Client, IMessage } from '@stomp/stompjs'

type UpdateCachedData = (
  _updateFunction: (_draft: PlayerBalanceType) => void,
) => void
export function balanceListener(
  client: Client,
  updateCachedData: UpdateCachedData,
) {
  const eventHandler = (message: IMessage) => {
    const body = message?.body

    if (body) {
      const data: WsBalanceType = JSON.parse(decodeURIComponent(body))

      if (data?.balance) {
        updateCachedData((cache) => ({ ...cache, ...data.balance }))
      }

      message.ack()
      addBreadcrumb({
        category: 'balanceWS',
        message: `Socket message received`,
        data,
        level: 'info',
      })
    }
  }

  const subscription = client.subscribe('/user/queue/balance', eventHandler)

  return () => subscription.unsubscribe()
}
