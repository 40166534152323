'use client'
import styles from '@/src/components/NavBar/NavBar.module.scss'
import Sidemenu from '@/src/components/NavBar/Sidemenu/Sidemenu'
import { TotalNotification } from '@/src/components/NotificationIcon'
import useAuthGuard from '@/src/hooks/useAuthGuard'
import { useGetBonuses } from '@/src/hooks/useGetBonuses'
import useMessages from '@/src/hooks/useMessages'
import SearchWidget from '@/src/widgets/SearchWidget'
import cn from 'classnames'
import { useState } from 'react'
import { HiOutlineSearch } from 'react-icons/hi'
import { IoMenu } from 'react-icons/io5'

type NavBarProps = {
  searchReplaceUrl?: boolean
}

const NavBar = ({ searchReplaceUrl }: NavBarProps) => {
  const [isSidemenuOpen, setIsSidemenuOpen] = useState<boolean | null>(null)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const { isLoggedIn } = useAuthGuard()
  const { unreadMessages } = useMessages()
  const { bonuses } = useGetBonuses()

  const notifications = unreadMessages + bonuses.length

  return (
    <div className='select-none'>
      <div className='flex items-center gap-4'>
        <div className='cursor-pointer'>
          {isLoggedIn && (
            <div className='relative left-[22.5px] top-[-2.5px]'>
              {unreadMessages > 0 && bonuses.length < 1 && (
                <div className={styles.ping} />
              )}
              <TotalNotification
                type='border'
                onClick={() => setIsSidemenuOpen(true)}
              />
            </div>
          )}
          <IoMenu
            size='32px'
            onClick={() => setIsSidemenuOpen(true)}
            className={cn('skew-x-[-15deg] text-secondary', styles.iconButton, {
              [styles.hasBadge]: isLoggedIn && notifications > 0,
            })}
          />
        </div>

        <HiOutlineSearch
          size='23px'
          onClick={() => setIsSearchOpen(true)}
          className={cn(
            'relative cursor-pointer text-secondary',
            styles.iconButton,
            {
              [styles.active]: isSidemenuOpen,
            },
          )}
        />
      </div>

      <SearchWidget
        onClose={() => setIsSearchOpen(false)}
        focusSearch={isSearchOpen}
        visible={isSearchOpen}
        replaceUrl={searchReplaceUrl}
      />

      <Sidemenu
        onClose={() => setIsSidemenuOpen(false)}
        isOpen={isSidemenuOpen}
      />
    </div>
  )
}

export default NavBar
