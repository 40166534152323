import { useGetLimitsQuery } from '@/src/services/player'
import { generateLimits } from '@/src/utils/limistHelper'
import { Trans } from 'react-i18next/TransWithoutContext'
import useCurrencyFormatter from '@/src/hooks/useCurrencyFormatter'

const CustomDLimitsErrorLabel = () => {

    const { data: limits } = useGetLimitsQuery()
    const { depositLimits } = generateLimits(limits)

    const dailyLimit = (depositLimits?.daily?.amount ?? 0) - (depositLimits?.daily?.used ?? 0);
    const weeklyLimit = (depositLimits?.weekly?.amount ?? 0) - (depositLimits?.weekly?.used ?? 0);
    const monthlyLimit = (depositLimits?.monthly?.amount ?? 0) - (depositLimits?.monthly?.used ?? 0);

    const { formatter } = useCurrencyFormatter({
        maxPrecision: 2,
        minPrecision: 0,
      })
    
    

    return (
        <Trans
            ns={'validations'}
            i18nKey='validations.amount.limits'
            components={{ br: <br /> }}
            values={{
                daily: formatter.format(dailyLimit),
                weekly: formatter.format(weeklyLimit),
                monthly: formatter.format(monthlyLimit)
            }}
        />
    )
}

export default CustomDLimitsErrorLabel