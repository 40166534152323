import paymentsApi from '@/src/services/payment'
import playerApi from '@/src/services/player'
import { store } from '@/src/store/store'
import { Client, IMessage } from '@stomp/stompjs'

const vouchersListeners = (client: Client) => {
  const createListener = (message: IMessage) => {
    if (message?.body) {
      store.dispatch(playerApi.util.invalidateTags(['Vouchers']))
      store.dispatch(paymentsApi.util.invalidateTags(['PaymentMethods']))
    }
  }

  const subscription = client.subscribe(
    '/user/queue/campaign-award',
    createListener,
  )

  return () => subscription.unsubscribe()
}

const unSubVouchersListeners = (client: Client) => {
  client.unsubscribe('/user/queue/campaign-award')
}

export { unSubVouchersListeners, vouchersListeners }
