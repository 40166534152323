import { api } from '@/src/services'
import {
  DepositStatusData,
  InitType,
  InitWithdrawType,
  PaymentMethodType,
  StatusType,
} from '@/src/services/types/payment.tsx'
import { getFormData } from '@/src/services/utils/index.tsx'

const paymentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Only when user is not logged in
    initDeposit: builder.mutation<
      InitType,
      {
        amount?: string
        method: string
        campaignId: number | null
        country?: string
      }
    >({
      query: ({ amount, method, campaignId, country = 'SWE' }) => ({
        url: 'payment/initDeposit',
        method: 'POST',
        body: getFormData({
          country: country,
          currency: country === 'SWE' ? 'SEK' : 'EUR',
          method,
          amount: amount || null,
          campaignId: campaignId,
        }),
      }),
      invalidatesTags: ['PreferredWithdrawalMethod'],
    }),
    // If user is currently loggedin use this endpoint
    confirmDeposit: builder.mutation<
      InitType,
      { amount?: string; method: string; campaignId: number | null }
    >({
      query: ({ amount, method, campaignId }) => ({
        url: 'payment/confirmDeposit',
        method: 'POST',
        body: getFormData({
          country: 'SWE',
          currency: 'SEK',
          amount,
          method,
          campaignId: campaignId,
        }),
      }),
      invalidatesTags: [
        'Balance',
        'DepositTransactions',
        'Bonuses',
        'Limits',
        'PreferredWithdrawalMethod',
      ],
    }),
    withdraw: builder.mutation<InitType, { amount?: string; method: string }>({
      query: ({ amount, method }) => ({
        url: 'payment/withdraw',
        method: 'POST',
        body: getFormData({
          country: 'SWE',
          currency: 'SEK',
          method,
          amount,
        }),
      }),
      invalidatesTags: ['Balance', 'WithdrawTransactions'],
    }),

    cancelWithdraw: builder.mutation<InitType, { reference: string }>({
      query: ({ reference }) => ({
        url: 'payment/cancelWithdraw',
        method: 'POST',
        body: getFormData({
          country: 'FIN',
          reference,
        }),
      }),
      invalidatesTags: ['WithdrawTransactions'],
    }),
    getDepositMethods: builder.query<{ methods: PaymentMethodType[] }, void>({
      query: () => 'payment/methods',
    }),
    initLogin: builder.mutation<InitType, { method: string; country?: string }>(
      {
        query: ({ method, country = 'SWE' }) => ({
          url: 'payment/initLogin',
          method: 'POST',
          body: getFormData({
            method: method,
            country: country,
          }),
        }),
      },
    ),
    initIdentityDeposit: builder.mutation<
      InitType,
      { reference: string; code: string }
    >({
      query: ({ reference, code }) => ({
        url: 'payment/identityDeposit',
        method: 'POST',
        body: getFormData({
          reference,
          code,
        }),
      }),
    }),
    initIdentityLogin: builder.mutation<
      InitType,
      { token: string; code: string }
    >({
      query: ({ token, code }) => ({
        url: 'payment/identityLogin',
        method: 'POST',
        body: getFormData({
          token,
          code,
        }),
      }),
    }),
    loginStatus: builder.mutation<StatusType, string>({
      query: (token) => ({
        url: 'payment/loginStatus',
        method: 'POST',
        body: getFormData({ token }),
      }),
    }),
    depositStatus: builder.mutation<StatusType, DepositStatusData>({
      query: (data) => ({
        url: 'payment/depositStatus',
        method: 'POST',
        body: getFormData(test(data)),
      }),
    }),
    getPaymentMethods: builder.query<{ methods: PaymentMethodType[] }, void>({
      query: () => 'payment/methods',
      providesTags: ['PaymentMethods'],
    }),
    getDefaultDepositMethod: builder.query<PaymentMethodType | undefined, void>(
      {
        query: () => 'payment/methods',
        transformResponse: (response: { methods?: PaymentMethodType[] }) => {
          return response?.methods?.find((m) => m.defaultMethod)
        },
      },
    ),
    getDefaultWithdrawMethod: builder.query<
      PaymentMethodType | undefined,
      void
    >({
      query: () => 'payment/initWithdraw',
      transformResponse: (response: { methods: PaymentMethodType[] }) => {
        return response?.methods.find((m) => m.defaultMethod)
      },
    }),
    getPreferredWithdrawMethod: builder.query<
      InitWithdrawType['preferredMethods'][0] | undefined,
      null
    >({
      query: () => 'payment/initWithdraw',
      transformResponse: (response: {
        preferredMethods: InitWithdrawType['preferredMethods']
      }) => {
        return response?.preferredMethods?.[0] || undefined
      },
      providesTags: ['PreferredWithdrawalMethod'],
    }),
    finishDeposit: builder.mutation<InitType, { reference: string }>({
      query: ({ reference }) => ({
        url: 'payment/finishDeposit',
        method: 'POST',
        body: getFormData({
          reference,
        }),
      }),
    }),
  }),
  overrideExisting: process.env.NODE_ENV === 'development',
})

export const {
  useInitLoginMutation,
  useInitIdentityLoginMutation,
  useInitIdentityDepositMutation,
  useInitDepositMutation,
  useConfirmDepositMutation,
  useDepositStatusMutation,
  useGetDepositMethodsQuery,
  useLoginStatusMutation,
  useWithdrawMutation,
  useCancelWithdrawMutation,
  useGetDefaultDepositMethodQuery,
  useGetDefaultWithdrawMethodQuery,
  useGetPaymentMethodsQuery,
  useFinishDepositMutation,
  useGetPreferredWithdrawMethodQuery,
} = paymentsApi

export const {
  getDefaultDepositMethod,
  getDefaultWithdrawMethod,
  getPaymentMethods,
  getDepositMethods,
} = paymentsApi.endpoints
//SSR endpoints
export default paymentsApi

function test(data: DepositStatusData) {
  if (data.token) return { token: data.token, reference: null }
  if (data.reference) return { reference: data.reference, token: null }
  return { token: null, reference: null }
}
