'use client'
import BackButton from '@/src/components/BackButton/BackButtonClient.tsx'
import { RoundedButton } from '@/src/components/Button'
import LocalizedLink from '@/src/components/LocalizedLink'
import Styles from '@/src/components/NavBar/Wallet/Wallet.module.scss'
import SearchBtn from '@/src/components/SearchBtn'
import SessionTimer from '@/src/components/SessionTimer'
import GameLimitsIcon from '@/src/components/SvgIcons/GameLimits'
import SelfTestIcon from '@/src/components/SvgIcons/SelfTest'
import SpelpausIcon from '@/src/components/SvgIcons/Spelpaus'
import { useTime } from '@/src/contexts/timeContext'
import useRouter from '@/src/hooks/Router/useRouter'
import { setReloadPaymentIq } from '@/src/store/slices/configsSlice'
import { useAppDispatch } from '@/src/store/store'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

type SGABarProps = {
  gameName?: string
  isLoggedIn?: boolean
  isInGame?: boolean
  searchReplaceUrl?: boolean
}

const SGABar = ({
  isLoggedIn,
  isInGame,
  gameName,
  searchReplaceUrl = false,
}: SGABarProps) => {
  const dispatch = useAppDispatch()
  const { replace } = useRouter()
  const { time } = useTime()

  const { t } = useTranslation(['common'])

  let touched = false

  const links = [
    {
      href: !isLoggedIn ? '/static/responsible-gambling' : '/my-account/responsible-gambling ',
      Icon: <GameLimitsIcon height='18' width='16' />,
      text: t('sga-game-limits'),
    },
    {
      href: 'https://www.spelpaus.se/sa-har-fungerar-det/om-webbplatsen/redirect',
      Icon: <SpelpausIcon height='18' width='16' />,
      text: t('sga-spelpaus'),
      openInNewTab: true,
    },
    {
      href: 'https://stodlinjen.se/spelberoende-test-pgsi',
      Icon: <SelfTestIcon height='18' width='16' />,
      text: t('sga-self-test'),
      openInNewTab: true,
    },
  ]

  return (
    <div
      className={cn(
        'z-10 flex w-full select-none flex-col items-center py-1.5 transition-all duration-300',
        {
          'h-[75px] bg-primary-shade': isInGame,
        },
      )}
    >
      <div className='flex h-5 w-full max-w-7xl justify-between px-2 sm:px-4'>
        <div className='flex items-center space-x-2'>
          <span className='text-lg font-bold opacity-50'>{time}</span>
          <SessionTimer isLoggedIn={isLoggedIn} />
        </div>
        <div className='flex items-center space-x-1'>
          {links.map(({ href, Icon, text, openInNewTab }, index) => (
            <div
              key={index}
              className='border-grey flex rounded border-[1px] font-bold opacity-80'
            >
              <LocalizedLink
                target={openInNewTab ? '_blank' : '_self'}
                href={href}
                className='flex items-center justify-center px-1 text-xs'
              >
                {Icon}
                <div className='ml-1 mt-[-1px] whitespace-nowrap'>{text}</div>
              </LocalizedLink>
            </div>
          ))}
        </div>
      </div>

      {isInGame && (
        <div className='relative mt-2 flex w-full max-w-7xl justify-between px-3'>
          <div className='z-10 flex justify-between'>
            <BackButton
              noText={true}
              className='z-10 origin-left scale-90 cursor-pointer'
            />
            <SearchBtn replaceUrl={isInGame || searchReplaceUrl} />
          </div>
          <span className='self-center truncate text-base font-bold uppercase'>
            {gameName}
          </span>
          {isLoggedIn ? (
            <RoundedButton
              onTouchStart={(e) => {
                e.preventDefault()
                touched = true
                dispatch(setReloadPaymentIq(true))
                replace('/pnp/deposit?init=true')
              }}
              onClick={(e) => {
                if (touched) {
                  touched = false
                  return
                }
                e.preventDefault()
                replace('/pnp/deposit?init=true')
                dispatch(setReloadPaymentIq(true))
              }}
              size={'small'}
              className={`z-10 origin-right scale-90 rounded-xl xl:origin-center ${Styles.walletButtonsInGame}`}
            >
              {t('deposit')}
            </RoundedButton>
          ) : (
            <LocalizedLink
              href={'/login'}
              replace
              className='origin-right scale-90 xl:origin-center'
            >
              <RoundedButton
                size={'small'}
                className={`z-10 origin-right scale-90 rounded-xl xl:origin-center ${Styles.walletButtonsInGame}`}
              >
                {t('common:play')}
              </RoundedButton>
            </LocalizedLink>
          )}
        </div>
      )}
    </div>
  )
}

export default SGABar
