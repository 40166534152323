import GameLink from '@/src/components/GameList/GameLink'
import { GameThumbnail } from '@/src/components/Thumbnail'
import { GameType } from '@/src/services/types'

type Props = {
  game: GameType
  sliderOverrideCss?: boolean
  favourite?: boolean
  showName?: boolean
}

function GameListItem({
  game,
  sliderOverrideCss,
  favourite = false,
  showName = false,
}: Props) {
  return (
    <div className={`flex justify-center align-middle`}>
      <GameLink game={game} className='h-full w-full'>
        <GameThumbnail
          game={game}
          sliderOverrideCss={sliderOverrideCss}
          favourite={favourite}
          showName={showName}
        />
      </GameLink>
    </div>
  )
}

export default GameListItem
