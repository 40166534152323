import { PositioningGroupType } from '@/src/services/types'
import {
  FamilyType,
  PositioningGroupTypeFromApi,
  PositioningProviderGamesType,
} from '@/src/services/types/games'
import { addBreadcrumb } from '@sentry/nextjs'

const filterFamilies = (groupData: PositioningGroupType) => {
  const familliesWithOneGame = filterFamilliesWithOneVersion(groupData.families)
  if (familliesWithOneGame.length) {
    addBreadcrumb({
      category: 'gamesList',
      message: 'Games with missing mobile and desktop versions detected ',
      data: familliesWithOneGame,
      level: 'fatal',
    })
  }
  const newFamilies = groupData.families
    // Only games with mobile and desktop version
    .filter((family) => family?.games?.length === 2)
  // Filter SGA license
  groupData.families = newFamilies
  return groupData
}

const filterFamilliesWithOneVersion = (groupData: FamilyType[]) => {
  return groupData
    .filter((family) => family?.games?.length != 2)
    .map((item) => ({
      familyId: item.familyId,
      familyName: item.familyName,
      tags: item.tags,
      missingVersion: item.games?.length
        ? !item.games[0]?.mobile
          ? 'mobile'
          : 'desktop'
        : 'both',
    }))
}

const transformProviders = (
  groupData: PositioningGroupTypeFromApi,
): PositioningGroupType => {
  const providers = !groupData.providers
    ? []
    : Object.entries(groupData.providers).map(([providerId, name]) => ({
        providerId: Number(providerId),
        name,
      }))

  return {
    ...groupData,
    providers,
  }
}

const filterProviderGames = (groupData: PositioningProviderGamesType) => {
  return groupData.games.filter((family) => family?.games?.length === 2)
}

export { filterFamilies, filterProviderGames, transformProviders }
