import playerApi from '@/src/services/player'
import { getAvailableSessionToken } from '@/src/utils/localStorage'
import { addBreadcrumb } from '@sentry/nextjs'
import { Client, IStompSocket, StompConfig } from '@stomp/stompjs'
import SockJS from 'sockjs-client'

type ClientKey = 'authenticated' | 'unauthenticated'

export const globalForWebSockets = globalThis as unknown as {
  clients: Map<ClientKey, Client> | undefined
}

export function getWebSocketClient(
  clientKey: ClientKey,
  config: StompConfig = {},
) {
  if (!globalForWebSockets.clients) {
    globalForWebSockets.clients = new Map()
  }

  if (globalForWebSockets.clients.has(clientKey)) {
    return globalForWebSockets.clients.get(clientKey)!
  }

  switch (clientKey) {
    case 'authenticated':
      config.connectHeaders = {
        'x-feSessionId': getAvailableSessionToken() ?? '',
        ...config.connectHeaders,
      }
      break
    case 'unauthenticated':
      config.connectHeaders = {
        'x-feSessionId': '',
        ...config.connectHeaders,
      }
  }

  const newClient = setupWebSocketClient(config)
  globalForWebSockets.clients.set(clientKey, newClient)
  return newClient
}

function setupWebSocketClient(config?: StompConfig) {
  addBreadcrumb({
    category: 'WS',
    message: 'Setting up websocket',
    data: { feSessionId: getAvailableSessionToken() },
    level: 'info',
  })

  const client = new Client({
    reconnectDelay: 1000,
    heartbeatIncoming: 3000,
    heartbeatOutgoing: 3000,
    ...config,
  })

  // Initiate the connection with the broker. If the connection breaks, as per Client#reconnectDelay, it will keep trying to reconnect.
  client.webSocketFactory = () => {
    const ws = new SockJS(`${process.env.NEXT_PUBLIC_WEBSOCKET_API}`)
    return ws as IStompSocket // Incompatible types according to the docs
  }

  client.debug = function (str) {
    addBreadcrumb({
      category: 'WS',
      message: 'debug',
      data: { str },
      level: 'debug',
    })
  }

  client.onConnect = (receipt) => {
    addBreadcrumb({
      category: 'WS',
      message: 'connected',
      data: { receipt },
      level: 'info',
    })
  }

  client.onStompError = (receipt) => {
    addBreadcrumb({
      category: 'WS',
      message: 'onStompError',
      data: { receipt },
      level: 'error',
    })
  }

  client.onWebSocketClose = (event: unknown) => {
    globalForWebSockets.clients?.forEach((client) => client.deactivate())
    globalForWebSockets.clients?.clear()

    // when session ends, BE kills the websocket connection
    // we will refetch some endpoint
    // so that we then can catch the NoAvailableSessionException error through the rtk base query and log the user out
    playerApi.util.invalidateTags(['Token', 'Balance'])

    addBreadcrumb({
      category: 'WS',
      message: 'onWebSocketClose',
      data: { event },
      level: 'error',
    })
  }

  return client
}

export async function clientConnected(client: Client): Promise<boolean> {
  return new Promise(async (resolve) => {
    if (!client.active) {
      client.activate()
    }

    // checking if the connection to the STOMP broker is established for 10 seconds
    for (let i = 0; i < 10; i++) {
      if (client.connected) {
        resolve(true)
        return
      }
      await new Promise((r) => setTimeout(r, 1000))
    }

    resolve(false)
  })
}
