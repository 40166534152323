import dateFormatter, {
  DateFormatterType,
} from '@/src/utils/services/dateFormatter'
import { useMemo, useState } from 'react'

function useDateFormatter(options?: DateFormatterType) {
  const [formatterOptions] = useState(options)

  const formatter = useMemo(() => {
    return dateFormatter({
      ...formatterOptions,
    })
  }, [formatterOptions])

  return { formatter } as const
}

export default useDateFormatter
